import React, { useCallback, useEffect } from "react";

// third-party imports
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

// project imports
import useAppointmentStore from "../../../store/appointmentsStore";
import MapComponentWithAddress from "./MapComponentWithAddress";
import AppointmentDetailsButton from "./AppointmentDetailsButton";
import useAppStore from "../../../store/appStore";
import { HospitalMeetDetailsPropTypes } from "../../../types/appointments";
import { isDoctor } from "../../../utils/auth";
import useAuthStore from "../../../store/authStore";

const HospitalMeetDetails: React.FC<HospitalMeetDetailsPropTypes> = ({
  appointmentCode,
  appointmentId,
  hospitalName,
  hospitalAddress,
  phoneNumber,
  status,
  availableSlot,
  detailsInfo,
  patientId,
  prescription,
  paymentStatus,
  patient,
  appointmentMode,
  doctor,
  appointmentDate,
  clinicalNotes,
  loadAppointmentDetails,
  usedIn = "",
  isFromMeet = false,
  isFromDashboard = false,
}) => {
  // props & state values
  const navigate = useNavigate();

  const { showSnackbar } = useAppStore();
  const { userRole } = useAuthStore();

  const {
    cancelAppointmentInfo,
    errorCancelAppointment,
    cleanUpCancelAppointmentState,
    cleanUpAppointmentState,
  } = useAppointmentStore();

  // callbacks & functions
  const handleNavigation = useCallback(() => {
    if (patientId) {
      navigate(`/patients/${patientId}/appointments`);
    } else {
      if (usedIn === "dashboard") {
        navigate("/dashboard");
      } else {
        navigate("/appointments");
      }
    }
  }, [navigate, patientId, usedIn]);

  useEffect(() => {
    if (errorCancelAppointment) {
      showSnackbar("Could not cancel appointment, please try again", "error");
      cleanUpCancelAppointmentState();
    }

    if (cancelAppointmentInfo) {
      showSnackbar("Appointment cancelled", "success");
      handleNavigation();
      cleanUpAppointmentState();
      cleanUpCancelAppointmentState();
    }

    // clean up on unmount
    return () => {
      cleanUpAppointmentState();
      cleanUpCancelAppointmentState();
    };
  }, [cancelAppointmentInfo, errorCancelAppointment, handleNavigation]);

  return (
    <Box sx={{ marginTop: "1.313rem", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.875rem",
          marginBottom: "1.563rem",
        }}
      >
        <Typography
          sx={{
            color: "var(--neuro-black-text)",
            lineHeight: "120%",
            fontSize: "var(--neuro-font-size-mini)",
            fontWeight: "var(--neuro-font-weight-bold)",
            fontFamily: "var(--neuro-font-family-inter)",
          }}
        >
          Meeting Details
        </Typography>
        <Typography
          sx={{
            color: "var(--neuro-black-text)",
            lineHeight: "var(--neuro-line-height-micro)",
            fontFamily: "var(--neuro-font-family-inter)",
          }}
        >
          {detailsInfo || ""}
        </Typography>
      </Box>

      {clinicalNotes && clinicalNotes.length > 0 && isDoctor(userRole) && (
        <Box
          sx={{
            marginBottom: "1.563rem",
          }}
        >
          <Typography
            sx={{
              color: "var(--neuro-black-text)",
              lineHeight: "120%",
              fontSize: "var(--neuro-font-size-mini)",
              fontWeight: "var(--neuro-font-weight-bold)",
              fontFamily: "var(--neuro-font-family-inter)",
              marginBottom: "0.875rem",
            }}
          >
            Clinical Notes
          </Typography>

          {clinicalNotes?.map((note) => (
            <Typography
              sx={{
                color: "var(--neuro-black-text)",
                lineHeight: "var(--neuro-line-height-micro)",
                fontFamily: "var(--neuro-font-family-inter)",
                marginBottom: "0.5rem",
              }}
            >
              {note || ""}
            </Typography>
          ))}
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6.5}>
          <MapComponentWithAddress address={hospitalAddress} />
        </Grid>

        <Grid item xs={12} md={5.5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.125rem",
              marginTop: "2.438rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "var(--neuro-font-size-mini)",
                fontWeight: "var(--neuro-font-weight-bold)",
                fontFamily: "var(--neuro-font-family-inter)",
                lineHeight: "120%",
                color: "var(--neuro-black-text)",
              }}
            >
              {hospitalName || ""}
            </Typography>
            <Box>
              <Typography
                sx={{
                  fontFamily: "var(--neuro-font-family-inter)",
                  color: "var(--neuro-black-text)",
                  lineHeight: "var(--neuro-line-height-smallest-x)",
                  marginBottom: "0.875rem",
                }}
              >
                {hospitalAddress || ""}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "var(--neuro-font-family-inter)",
                  color: "var(--neuro-black-text)",
                }}
              >
                {phoneNumber || ""}
              </Typography>

              <AppointmentDetailsButton
                appointmentCode={appointmentCode}
                id={appointmentId}
                status={status}
                availableSlot={availableSlot}
                hospitalAddress={hospitalAddress}
                meetType="Clinic"
                prescription={prescription}
                paymentStatus={paymentStatus}
                patientId={patientId}
                patient={patient}
                appointmentMode={appointmentMode}
                doctor={doctor}
                appointmentDate={appointmentDate}
                isFromMeet={isFromMeet}
                loadAppointmentDetails={loadAppointmentDetails}
                isFromDashboard={isFromDashboard}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HospitalMeetDetails;
