import React from "react";
import { Box } from "@mui/material";
import SidebarMenu from "./SidebarMenu";
import SidebarFooter from "./SidebarFooter";
import { SidebarContentProps } from "../../types/common";
import useAuthStore from "../../store/authStore";
import { ADMIN } from "../../utils/constants";

const SidebarContent: React.FC<SidebarContentProps> = ({
  menuItems,
  isMenuCollapsed,
  drawerOpen = false,
}) => {
  // props & state values
  const { userRole } = useAuthStore();

  return (
    <Box
      sx={{
        width: isMenuCollapsed ? (drawerOpen ? "13.75rem" : 0) : "13.75rem",
        minWidth: isMenuCollapsed ? (drawerOpen ? "13.75rem" : 0) : "13.75rem",
        transition: "width 0.3s ease-in-out, min-width 0.3s ease-in-out",
        backgroundColor: "var(--neuro-white-text)",
        // height: "100vh",
        height: "100%",
        color: "var(--neuro-black-text)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRight: "0.063rem solid var(--neuro-secondary_border)",
        // overflow: "auto",
        overflow: "hidden",
        // position: "relative",
      }}
    >
      <Box
        sx={{
          height: userRole === ADMIN ? "100%" : "calc(100% - 11.25rem)",
          // marginTop: "1rem",
        }}
      >
        <SidebarMenu menuItems={menuItems} />
      </Box>
      {userRole !== ADMIN && <SidebarFooter />}
    </Box>
  );
};

export default SidebarContent;
