import { Box, Grid, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import ComponentHeader from "../../shared/ComponentHeader";
import HorizontalTabs from "./HorizontalTabs";
import PersonalInfoPage from "./PersonalInfoPage"; // Assuming these components exist
import PatientFormsList from "../../patients/PatientFormsList";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";
import DoctorPersonalInfoPage from "../doctor/DoctorPersonalInfoPage";
import AdminPersonalInfoPage from "../admin/AdminPersonalInfoPage";
import { capitalizeWordsPreservingSpaces } from "../../../utils/common";
import AbhaCardDetails from "./AbhaCardDetails";
import Button from "../../ui/Button";
import DoctorDetails from "../../doctors/DoctorDetails";
import PatientDetails from "../../patients/PatientDetails";
import AdminDetails from "../admin/AdminDetails";
import { ADMIN, DOCTOR, NETWORK } from "../../../utils/constants";
import { updateData } from "../../../services/patientService";

const PatientProfile = () => {
  // props & state values
  const navigate = useNavigate();
  const location = useLocation();
  const { user, userRole } = useAuthStore();
  const isMobile = useMediaQuery("(max-width:37.5rem)");

  const [showProfileEdit, setShowProfileEdit] = useState<boolean>(false);

  const tabs =
    userRole === DOCTOR || userRole === ADMIN || userRole === NETWORK
      ? ["Personal Info"]
      : ["Personal Info", "Forms", "ABHA Details"];

  // Initialize selectedTab based on the URL
  const initialTab = location.pathname.includes("forms")
    ? "Forms"
    : location.pathname.includes("bha-details")
      ? "ABHA Details"
      : "Personal Info";

  const [selectedTab, setSelectedTab] = useState<string>(initialTab);

  // callbacks & functions
  const onTabClick = (tab: string) => {
    setSelectedTab(tab);
    if (tab === "Personal Info") {
      navigate(`/profile/personal-info`);
    }
    if (tab === "Forms") {
      navigate(`/profile/forms`);
    }
    if (tab === "ABHA Details") {
      navigate(`/profile/abha-details`);
    }
  };

  useEffect(() => {
    if (location.pathname.includes("personal-info")) {
      setSelectedTab("Personal Info");
    } else if (location.pathname.includes("forms")) {
      setSelectedTab("Forms");
    }
  }, [location.pathname]);

  const handleProfileViewEdit = () => {
    setShowProfileEdit((prev) => !prev);
  };

  const handleUpdateData = async () => {
    try {
      await updateData();
    } catch (error) {
      console.error("Could not update data", error);
    }
  };

  return (
    <Box
      sx={{
        paddingBlock: "1rem",
        paddingInline: "0.875rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "100%",
      }}
    >
      <ComponentHeader title="Profile" />

      {/* Horizontal Tabs */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isMobile ? "flex-start" : "center",
          gap: "0.625rem",
          // minHeight: "2.25rem",
        }}
      >
        <HorizontalTabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          onClick={onTabClick}
          from="profile"
        />

        <Box sx={{ display: "flex", alignItems: "center", gap: "1.25rem" }}>
          {user?.email === "demo+a@racchabanda.com" && (
            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              onClick={handleUpdateData}
            >
              Update Data
            </Button>
          )}

          {selectedTab === "Personal Info" && (
            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              onClick={handleProfileViewEdit}
            >
              {showProfileEdit ? "View Profile" : "Edit Profile"}
            </Button>
          )}
        </Box>
      </Box>

      {/* Main Content */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12}>
          {selectedTab === "Personal Info" &&
            (userRole === DOCTOR || userRole === NETWORK ? (
              showProfileEdit ? (
                <DoctorPersonalInfoPage />
              ) : (
                <DoctorDetails doctorId={user?.doctorId || ""} from="profile" />
              )
            ) : userRole === ADMIN ? (
              showProfileEdit ? (
                <AdminPersonalInfoPage />
              ) : (
                <AdminDetails id={user?.doctorId || ""} />
              )
            ) : showProfileEdit ? (
              <PersonalInfoPage />
            ) : (
              <PatientDetails id={user?.patientId || ""} />
            ))}

          {selectedTab === "Forms" && (
            <PatientFormsList
              patientId={user?.patientId}
              patientName={capitalizeWordsPreservingSpaces(user?.name || "")}
            />
          )}

          {selectedTab === "ABHA Details" && (
            <Box sx={{ width: "100%", height: "100%" }}>
              <AbhaCardDetails userId={user?.userId || ""} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientProfile;
