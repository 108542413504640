import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography, TextField } from "@mui/material";
import PdfViewer from "./PdfViewer";
import DicomViewer from "./DicomViewer";
import DownloadSvgIcon from "../../svg/DownloadIcon";
import Tooltip from "../../ui/Tooltip";
import LoadingScreen from "../../shared/LoadingScreen";
import ContentCenter from "../../shared/ContentCenter";
import DeleteIcon from "../../svg/DeleteIcon";
import ConfirmModal from "../../shared/ConfirmModal";
import EditIcon from "@mui/icons-material/Edit";
import { updateDocumentName } from "../../../services/documentsService";
import useAppStore from "../../../store/appStore";
import useDocumentStore from "../../../store/documentsStore";
import { OriginalReportProps } from "../../../types/documents";
import Mp3Player from "./Mp3Player";
import { useNavigate } from "react-router-dom";
// import ZipFileViewer from "./ZipFileViewer";

const OriginalReport: React.FC<OriginalReportProps> = ({
  loading,
  error,
  fileType,
  filePath,
  fileName,
  id,
  status,
  patientId,
  isSingleDocument = false,
  from = "",
}) => {
  // props & state values
  const useStyles = {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "1.813rem",
      // paddingInline: "1.25rem",
      paddingRight: "1.25rem",
      borderRight:
        from === "conference"
          ? "none"
          : "0.063rem solid var(--neuro-secondary_border)",
      height: "100%",
      // overflowY: "auto",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBottom: "1.188rem",
      borderBottom: "0.063rem dashed var(--neuro-secondary_border)",
      minHeight: "3.75rem",
    },
    title: {
      fontSize: "var(--neuro-font-size-tiny)",
      lineHeight: "var(--neuro-line-height-micro)",
      color: "var(--neuro-black-text)",
      fontFamily: "var(--neuro-font-family-roboto-condensed)",
      marginRight: "0.25rem",
    },
    content: {
      p: "1.5rem",
      borderRadius: "0.625rem",
      backgroundColor: "var(--neuro-white-text)",
      border: "0.063rem solid var(--neuro-secondary_border)",
      height: "calc(100% - 6.25rem)",
      overflow: "auto",
    },
    image: {
      width: "100%",
      borderRadius: "0.75rem",
    },
  };

  const { showSnackbar, setDocumentsConferenceView } = useAppStore();
  const navigate = useNavigate();

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState("");

  const {
    deleteDocument,
    loadingDeleteDocument,
    errorDeleteDocument,
    documentDeleted,
    cleanUpDeleteDocumentState,
  } = useDocumentStore();

  // callbacks & functions
  useEffect(() => {
    if (isSingleDocument) {
      if (loadingDeleteDocument) {
        showSnackbar("Deleting document, please wait", "info");
      } else if (errorDeleteDocument) {
        showSnackbar("Could not delete document, please try again", "error");
        cleanUpDeleteDocumentState();
      } else if (documentDeleted) {
        showSnackbar("Document deleted", "success");
        if (patientId) {
          if (from === "meet") {
            setDocumentsConferenceView("Details");
          } else {
            navigate(`/patients/${patientId}/documents/view`);
          }
        } else {
          navigate("/documents/view");
        }
        cleanUpDeleteDocumentState();
      }
    }
  }, [
    loadingDeleteDocument,
    errorDeleteDocument,
    documentDeleted,
    isSingleDocument,
  ]);

  const documentNameUpdateHandler = async (id: string, data: any) => {
    try {
      await updateDocumentName(id, data);
    } catch (error) {
      console.error("Error updating document name");
    }
  };

  useEffect(() => {
    setText(fileName);
  }, [fileName]);

  const renderFileViewer = () => {
    const viewerMap: { [key: string]: JSX.Element } = {
      PDF: <PdfViewer key={filePath} pdfUrl={filePath} />,
      IMAGE: (
        <img
          key={filePath}
          src={filePath}
          alt="Original Report"
          style={useStyles.image}
        />
      ),
      DICOM: <DicomViewer key={filePath} dicomUrl={filePath} />,
      AUDIO: <Mp3Player filePath={filePath} />,
      // ZIP: <ZipFileViewer zipUrl={filePath} />,
    };

    return viewerMap[fileType] || "Not supported file";
  };

  const handleReportDownload = async () => {
    if (filePath) {
      try {
        // Fetch the file to ensure it exists and create a Blob URL if needed
        const response = await fetch(filePath);

        if (!response.ok) {
          throw new Error("File not found");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element and trigger download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filePath.split("/").pop() || "download"); // Set the filename
        document.body.appendChild(link);

        link.click();

        // Clean up by revoking the Blob URL and removing the element
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Download error:", error);
        showSnackbar("Could not download the file, please try again", "info");
      }
    } else {
      showSnackbar("Could not download the file, please try again", "info");
    }
  };

  const handleDeleteDocumet = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpenConfirmModal(true);
    // deleteDocument(id);
  };

  const handleConfirm = (id: string) => {
    if (patientId) {
      deleteDocument(id, patientId);
    } else {
      deleteDocument(id);
    }
    setOpenConfirmModal(false);
  };

  const handleCancel = () => {
    setOpenConfirmModal(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    documentNameUpdateHandler(id, { name: text });
  };

  const handleChange = (event: any) => {
    setText(event.target.value);
  };

  return (
    <Box sx={useStyles.container}>
      <Box sx={useStyles.header}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 0.75,
            width: "75%",
          }}
        >
          {isEditing ? (
            <TextField
              value={text}
              onChange={handleChange}
              onBlur={handleSaveClick}
              autoFocus
              fullWidth
              sx={{
                fontSize: "var(--neuro-font-size-tiny)",
                lineHeight: "var(--neuro-line-height-micro)",
                color: "var(--neuro-black-text)",
                fontFamily: "var(--neuro-font-family-roboto-condensed)",
                marginRight: "0.5rem",
                "& .MuiOutlinedInput-root": {
                  "& input": {
                    padding: 0, // Remove inner padding of input
                    fontSize: "var(--neuro-font-size-tiny)",
                    lineHeight: "var(--neuro-line-height-micro)",
                    color: "var(--neuro-black-text)",
                    fontFamily: "var(--neuro-font-family-roboto-condensed)",
                  },
                  "& fieldset": {
                    // borderColor: "transparent", // Remove border color
                  },
                  "&:hover fieldset": {
                    // borderColor: "transparent", // Remove border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent", // Remove border color on focus
                    boxShadow: "none", // Remove blue border shadow
                  },
                },
              }}
            />
          ) : (
            <Typography
              onClick={handleEditClick}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%", // Adjust the width as needed
                ...useStyles.title, // Spread other styles if necessary
              }}
            >
              {text}
            </Typography>
          )}

          {!isEditing && (
            <IconButton onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
          )}
        </Box>

        <Box
          sx={{
            flex: 0.25,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {from !== "conference" && (
            <>
              {status === "DELETED" ? (
                <IconButton
                  disabled={true}
                  onClick={(event) => handleDeleteDocumet(event)}
                  sx={{
                    "&.Mui-disabled > svg": {
                      opacity: 0.5,
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <Tooltip title="Delete Document" placement="top">
                  <IconButton
                    onClick={(event) => handleDeleteDocumet(event)}
                    sx={{
                      "&.Mui-disabled > svg": {
                        opacity: 0.5,
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}

          <ConfirmModal
            open={openConfirmModal}
            description="Are you sure, you want to delete this document"
            handleConfirm={() => handleConfirm(id)}
            handleCancel={handleCancel}
          />

          <Tooltip title="Download" placement="top">
            <IconButton onClick={handleReportDownload}>
              <DownloadSvgIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {loading && <LoadingScreen />}

      {error && (
        <ContentCenter>
          <Typography className="no-data">Documents not found</Typography>
        </ContentCenter>
      )}

      {!loading && !error && (
        <Box sx={useStyles.content}>{renderFileViewer()}</Box>
      )}
    </Box>
  );
};

export default OriginalReport;
