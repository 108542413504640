import { useLocation } from "react-router-dom";
import {
  Box,
  //  Drawer,
  useMediaQuery,
} from "@mui/material";
import useAuthStore from "../../store/authStore";
import SidebarContent from "./SidebarContent";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FolderIcon from "@mui/icons-material/Folder";
import HomeIcon from "@mui/icons-material/Home";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import MedicationIcon from "@mui/icons-material/Medication";
import PeopleIcon from "@mui/icons-material/People";
// import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import ShareIcon from "@mui/icons-material/Share";
import useAppStore from "../../store/appStore";
import CustomDrawer from "./CustomDrawer";
import { ADMIN } from "../../utils/constants";

const NewSidebar = () => {
  const { isMenuCollapsed, drawerOpen } = useAppStore();
  const location = useLocation();
  console.log("location in sidebar", location);
  const isMobile = useMediaQuery("(max-width:800px)");

  const { userRole } = useAuthStore();

  const menuItems = [
    {
      route: "dashboard",
      text: "Dashboard",
      icon: <HomeIcon />,
      selected: location.pathname.includes("dashboard"),
      roles: ["PATIENT", "DOCTOR", "ADMIN", "NETWORK"],
    },
    {
      route: "appointments",
      text: "Appointments",
      icon: <CalendarTodayIcon />,
      selected:
        (location.pathname.includes("appointments") &&
          !location.pathname.includes("patients")) ||
        (location.pathname.includes("appointment") &&
          !location.pathname.includes("patients")) ||
        location.pathname.includes("meet") ||
        location.pathname.includes("specialties") ||
        Boolean(
          location.pathname.match(/^\/doctors\/[^/]+\/details$/) &&
            userRole !== ADMIN
        ),
      roles: ["PATIENT", "DOCTOR", "ADMIN"],
    },
    // {
    //   route: "settings",
    //   text: "Settings",
    //   icon: <CalendarTodayIcon />,
    //   selected: location.pathname.includes("settings"),
    //   roles: ["PATIENT", "DOCTOR"],
    // },
    {
      route: "documents",
      text: "Documents",
      icon: <FolderIcon />,
      selected:
        location.pathname.includes("documents") &&
        !location.pathname.includes("patients"),
      roles: ["PATIENT"],
    },
    {
      route: "referrals",
      text: "Referrals",
      icon: <ShareIcon />,
      selected: location.pathname.includes("referrals"),
      roles: ["NETWORK", "ADMIN"],
    },
    {
      route: "patients",
      text: "Patients",
      icon: <PeopleIcon />,
      selected: location.pathname.includes("patients"),
      roles: ["ADMIN"],
      // submenus
      // [
      //       {
      //         route: "patients/documents",
      //         text: "Documents",
      //         icon: <FolderIcon />,
      //         selected: location.pathname.includes("patients/documents"),
      //         roles: ["ADMIN"],
      //       },
      //       {
      //         route: "patients/appointments",
      //         text: "Appointments",
      //         icon: <CalendarTodayIcon />,
      //         selected: location.pathname.includes("patients/appointments"),
      //         roles: ["ADMIN"],
      //       },
      //       {
      //         route: "patients/forms",
      //         text: "Forms",
      //         icon: <DynamicFormIcon />,
      //         selected: location.pathname.includes("patients/forms"),
      //         roles: ["ADMIN"],
      //       },
      //     ]
    },
    {
      route: "clinics",
      text: "Clinics",
      icon: <LocalHospitalIcon />,
      selected: location.pathname.includes("clinics"),
      roles: ["ADMIN"],
    },
    {
      route: "doctors",
      text: "Doctors",
      icon: <MedicationIcon />,
      selected: location.pathname.includes("doctors"),
      roles: ["ADMIN"],
    },
  ];

  const filteredMenuItems = menuItems.filter((item) =>
    item.roles.includes(userRole as string)
  );

  return (
    <Box>
      {isMobile ? (
        // <Drawer
        //   anchor="left"
        //   open={drawerOpen}
        //   // ModalProps={{
        //   //   keepMounted: true, // Better open performance on mobile.
        //   // }}
        //   sx={{
        //     backgroundColor: "transparent",
        //     boxShadow: "none",
        //     "& .MuiDrawer-paper": {
        //       backgroundColor: "transparent",
        //       boxShadow: "none",
        //       // height: "100%",
        //     },
        //   }}
        // >
        //   <Box
        //     display="flex"
        //     justifyContent="flex-start"
        //     alignItems="flex-start"
        //   >
        //     <SidebarContent
        //       menuItems={filteredMenuItems}
        //       isMenuCollapsed={isMenuCollapsed}
        //       drawerOpen={drawerOpen}
        //     />
        //   </Box>
        // </Drawer>
        <CustomDrawer open={drawerOpen}>
          <SidebarContent
            menuItems={filteredMenuItems}
            isMenuCollapsed={isMenuCollapsed}
          />
        </CustomDrawer>
      ) : (
        <SidebarContent
          menuItems={filteredMenuItems}
          isMenuCollapsed={isMenuCollapsed}
        />
      )}
    </Box>
  );
};

export default NewSidebar;
