import React from "react";
import { Menu, MenuItem, Box } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "../../ui/Button";
import { SortByDocumentsProps } from "../../../types/documents";

const SortByDocuments: React.FC<SortByDocumentsProps> = ({
  options,
  selectedOption,
  setSelectedOption,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option?: string) => {
    if (option) {
      setSelectedOption(option);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant="outlined"
        className="outlined-secondary-button"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ width: "16.25rem" }}
        startIcon={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ArrowUpwardIcon
                sx={{ fontSize: "var(--neuro-font-size-smaller-plus)" }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ArrowDownwardIcon
                sx={{ fontSize: "var(--neuro-font-size-smaller-plus)" }}
              />
            </Box>
          </Box>
        }
      >
        Sort by:{" "}
        <span
          style={{
            marginLeft: "0.5rem",
            fontWeight: "var(--neuro-font-weight-bold)",
          }}
        >
          {selectedOption}
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
        sx={{
          "& .MuiPaper-root": {
            width: "16.25rem",
            maxWidth: "16.25rem",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={() => handleClose(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SortByDocuments;
