import { Box, Grid } from "@mui/material";
import useAuthStore from "../../../store/authStore";
import { PATIENT } from "../../../utils/constants";
import useAppStore from "../../../store/appStore";
import { useEffect, useState } from "react";
import OriginalReport from "./OriginalReport";
import useDocumentStore from "../../../store/documentsStore";
import ReportSummary from "./ReportSummary";
import { SingleReportViewerProps } from "../../../types/documents";

const SingleReportViewer: React.FC<SingleReportViewerProps> = ({
  patientId,
  from = "",
}) => {
  // props & state values
  const { userRole } = useAuthStore();

  const { isMenuCollapsed, setIsMenuCollapsed } = useAppStore();

  const [previousSidebarCollpased, _] = useState<boolean>(
    isMenuCollapsed ? true : false
  );

  const {
    reGeneratedSummaryData,
    cleanUpReGenerateSummaryState,
    documentDetails,
    loadingDocumentDetails,
    errorDocumentDetails,
    fetchDocumentDetails,
    cleanUpDocumentDetailsStore,
    selectedDocumentId,
  } = useDocumentStore();

  // callbacks & functions
  useEffect(() => {
    setIsMenuCollapsed(true);

    return () => {
      cleanUpDocumentDetailsStore();
      setIsMenuCollapsed(previousSidebarCollpased);
    };
  }, []);

  useEffect(() => {
    if (selectedDocumentId) {
      fetchDocumentDetails(selectedDocumentId);
      cleanUpReGenerateSummaryState();
    }
  }, [selectedDocumentId, reGeneratedSummaryData]);

  return (
    <Box
      p={2.5}
      sx={{
        backgroundColor: "var(--neuro-white-text)",
        height: "100%",
      }}
    >
      <Grid
        container
        spacing={2}
        flexWrap="wrap"
        sx={{ height: "100%", overflow: "auto" }}
      >
        {userRole === PATIENT && (
          <>
            <Grid
              item
              xs={12}
              md={isMenuCollapsed ? (from === "meet" ? 12 : 8.5) : 8}
              xl={from === "meet" ? 12 : 9}
              sx={{
                height: "100%",
                overflowY: "hidden",
              }}
            >
              <OriginalReport
                key={documentDetails?.id}
                loading={loadingDocumentDetails}
                error={Boolean(errorDocumentDetails)}
                fileType={documentDetails?.fileType}
                filePath={documentDetails?.filePath}
                fileName={documentDetails?.name}
                id={documentDetails?.id}
                status={documentDetails?.status}
                patientId={patientId}
                isSingleDocument
                from={from}
              />
            </Grid>
          </>
        )}

        {userRole !== PATIENT && (
          <>
            <Grid
              item
              xs={12}
              md={from === "meet" ? 12 : 6}
              sx={{
                height: "100%",
                overflowY: "hidden",
              }}
            >
              <OriginalReport
                loading={loadingDocumentDetails && !documentDetails}
                error={Boolean(errorDocumentDetails) && !documentDetails}
                fileType={documentDetails?.fileType}
                filePath={documentDetails?.filePath}
                fileName={documentDetails?.name}
                id={documentDetails?.id}
                status={documentDetails?.status}
                patientId={patientId}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={from === "meet" ? 12 : 6}
              sx={{ height: "100%", overflowY: "hidden" }}
            >
              <ReportSummary
                loading={loadingDocumentDetails}
                id={documentDetails?.id}
                metadata={documentDetails?.metadata}
                doctorSummary={documentDetails?.doctorSummary}
                patientSummary={documentDetails?.patientSummary}
                fileName={documentDetails?.name}
                fileType={documentDetails?.fileType}
                isDoctorApproved={documentDetails?.isDoctorApproved}
                feedback={documentDetails?.feedback}
                transcriptions={documentDetails?.transcriptions}
                patientNote={documentDetails?.patientNote}
                symptomAssessment={documentDetails?.symptomAssessment}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default SingleReportViewer;
