import { Typography } from "@mui/material";
import { isAdmin, isDoctor } from "../../../utils/auth";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";
import useAppStore from "../../../store/appStore";

const PatientName = ({
  id,
  name,
  patient,
}: {
  id: string;
  name: string;
  patient: any;
}) => {
  // props & state value
  const navigate = useNavigate();
  const { userRole } = useAuthStore();
  const { setSelectedPatient } = useAppStore();

  return (
    <Typography
      sx={{
        marginTop: "0.75rem",
        fontWeight: "var(--neuro-font-weight-bold)",
        color: "var(--neuro-bg-darkblue-primary)",
        fontFamily: "var(--neuro-font-family-roboto)",
        lineHeight: "var(--neuro-line-height-tiny-plus)",
        "&:hover": {
          color: "var(--neuro-button-bg-primary)",
        },
      }}
      onClick={(event) => {
        event.stopPropagation();
        if (isDoctor(userRole) || isAdmin(userRole)) {
          // navigate(`/patients/${id || ""}/details`);
          setSelectedPatient(patient);
          navigate(`/appointments/patient/${id}/profile`);
        }
      }}
    >
      {name}
    </Typography>
  );
};

export default PatientName;
