import React, { useState } from "react";
import { deleteDoctor } from "../../services/doctorService";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddEditDoctorModal from "./AddEditDoctorModal";
import ConfirmDialog from "../shared/ConfirmDialog";
import { Doctor } from "../../types/common";
import ComponentHeader from "../shared/ComponentHeader";
import { DoctorsListProps } from "../../types/doctors";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/common";
import Button from "../ui/Button";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import { tableStyles } from "../../utils/styles";
// import DashboardFilter from "../shared/DashboardFilter";

const DoctorsList: React.FC<DoctorsListProps> = ({
  loading,
  doctors,
  loadDoctors,
}) => {
  // props & state values
  console.log("doctors in list", doctors);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [deleteDoctorId, setDeleteDoctorId] = useState<string | null>(null);
  const [editDoctor, setEditDoctor] = useState<Doctor | null>(null);

  // callbacks & functions
  const handleAddNew = () => {
    setEditDoctor(null);
    setDialogOpen(true);
  };

  const handleEdit = (doctor: Doctor) => {
    setEditDoctor(doctor);
    setDialogOpen(true);
  };

  const handleDelete = async (id: string) => {
    setDeleteDoctorId(id);
    setConfirmOpen(true);
  };

  const handleConfirmClose = async (confirmed: boolean) => {
    setConfirmOpen(false);
    if (confirmed && deleteDoctorId) {
      try {
        await deleteDoctor(deleteDoctorId);
        loadDoctors();
      } catch (error) {
        console.error("Error deleting doctor:", error);
      }
    }
    setDeleteDoctorId(null);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    loadDoctors();
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          minHeight: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
          // marginTop={"15px"}
        >
          <ComponentHeader title="Doctor List" />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.5rem",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              startIcon={<AddIcon />}
              onClick={handleAddNew}
            >
              Add New
            </Button>
            {/* <DashboardFilter /> */}
          </Box>
        </Box>

        {loading ? (
          <LoadingScreen />
        ) : doctors && doctors.length > 0 ? (
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none",
              border: "0.063rem solid var(--neuro-secondary_border)",
              borderBottom: "none",
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    "& >th": {
                      p: "0.5rem",
                    },
                  }}
                >
                  <TableCell sx={tableStyles.tableCell}>Name(Years)</TableCell>

                  <TableCell sx={tableStyles.tableCell}>
                    Specializations
                  </TableCell>

                  <TableCell sx={tableStyles.tableCell}>Languages</TableCell>

                  <TableCell sx={tableStyles.tableCell}>Clinic</TableCell>

                  <TableCell sx={tableStyles.tableCell}>
                    Registered On
                  </TableCell>

                  <TableCell sx={tableStyles.tableCell}>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {doctors?.map((doctor) => (
                  <TableRow
                    key={doctor.id}
                    sx={{
                      "& >td": {
                        p: "0.5rem",
                      },
                    }}
                  >
                    <TableCell
                      sx={{
                        fontWeight: 500,
                        color: "var(--neuro-table-head-primary)",
                        fontFamily: "var(--neuro-font-family-roboto-condensed)",
                        fontSize: "14px",
                      }}
                    >{`${capitalizeFirstLetter(doctor?.firstName || "")}(${doctor?.yearsOfExperience || ""}${doctor?.yearsOfExperience ? "yrs" : "-"})`}</TableCell>

                    <TableCell sx={tableStyles.subTableCell}>
                      {doctor.specializations.join(", ")}
                    </TableCell>

                    <TableCell sx={tableStyles.subTableCell}>
                      {doctor.spokenLanguages.join(", ")}
                    </TableCell>

                    <TableCell sx={tableStyles.subTableCell}>
                      {doctor?.hospital?.name || "-"}
                    </TableCell>

                    <TableCell sx={tableStyles.subTableCell}>
                      {getFormattedDate(doctor?.createdAt || "-")}
                    </TableCell>

                    <TableCell sx={tableStyles.subTableCell}>
                      <Box display="flex" justifyContent="space-around">
                        <IconButton
                          onClick={() => handleEdit(doctor)}
                          color="secondary"
                          disableRipple
                          sx={{
                            p: 0,
                            "&:hover": { backgroundColor: "transparent" },
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDelete(doctor.id)}
                          color="primary"
                          disableRipple
                          sx={{
                            p: 0,
                            "&:hover": { backgroundColor: "transparent" },
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <ContentCenter>
            <Typography className="no-data">No doctors found</Typography>
          </ContentCenter>
        )}

        <AddEditDoctorModal
          open={dialogOpen}
          onClose={handleCloseDialog}
          doctor={editDoctor}
          loadDoctors={loadDoctors}
        />

        <ConfirmDialog
          open={confirmOpen}
          title="Confirm Delete"
          message="Are you sure you want to delete this doctor?"
          onClose={handleConfirmClose}
        />
      </Box>
    </>
  );
};

export default DoctorsList;
