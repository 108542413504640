import React, { useState } from "react";
import { deleteHospital } from "../../services/hospitalService";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddEditHospitalModal from "./AddEditHospitalModal";
import ConfirmDialog from "../shared/ConfirmDialog";
import { Hospital } from "../../types/common";
import ComponentHeader from "../shared/ComponentHeader";
import { HospitalsListProps } from "../../types/hospitals";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/common";
import Button from "../ui/Button";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import { tableStyles } from "../../utils/styles";
// import DashboardFilter from "../shared/DashboardFilter";

const HospitalsList: React.FC<HospitalsListProps> = ({
  hospitals,
  loading,
  loadHospitals,
}) => {
  // props & state values
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [deleteHospitalId, setDeleteHospitalId] = useState<string | null>(null);
  const [editHospital, setEditHospital] = useState<Hospital | null>(null);

  // callbacks & functions
  const handleAddNew = () => {
    setEditHospital(null);
    setDialogOpen(true);
  };

  const handleEdit = (hospital: Hospital) => {
    setEditHospital(hospital);
    setDialogOpen(true);
  };

  const handleDelete = async (id: string) => {
    setDeleteHospitalId(id);
    setConfirmOpen(true);
  };

  const handleConfirmClose = async (confirmed: boolean) => {
    setConfirmOpen(false);
    if (confirmed && deleteHospitalId) {
      try {
        await deleteHospital(deleteHospitalId);
        loadHospitals();
      } catch (error) {
        console.error("Error deleting hospital:", error);
      }
    }
    setDeleteHospitalId(null);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    loadHospitals();
  };

  const fullHospitalAddess = (city: any, state: any, country: any): string => {
    // `${hospital.city} ${hospital.state} ${hospital.country}`
    let address = "";

    if (city && city !== "") {
      address = `${city},`;
    }

    if (state && state !== "") {
      address = `${address} ${state},`;
    }

    if (country && country !== "") {
      address = `${address} ${country}`;
    }

    return address || "";
  };

  const showContactNumber = (number: string | null | undefined) => {
    if (!number || number === "") {
      return "-";
    } else {
      if (number.includes("+")) {
        return number;
      } else {
        return `+91${number}`;
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <ComponentHeader title="Clinic List" />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.5rem",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              startIcon={<AddIcon />}
              onClick={handleAddNew}
            >
              Add New
            </Button>
            {/* <DashboardFilter/> */}
          </Box>
        </Box>

        {loading ? (
          <LoadingScreen />
        ) : hospitals && hospitals.length > 0 ? (
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none",
              border: "0.063rem solid var(--neuro-secondary_border)",
              borderBottom: "none",
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    "& >th": {
                      p: "0.5rem",
                    },
                  }}
                >
                  <TableCell sx={tableStyles.tableCell}>Clinic Name</TableCell>

                  <TableCell sx={tableStyles.tableCell}>Address</TableCell>

                  <TableCell sx={tableStyles.tableCell}>
                    Contact Number
                  </TableCell>

                  <TableCell sx={tableStyles.tableCell}>Email</TableCell>

                  <TableCell sx={tableStyles.tableCell}>
                    Registered On
                  </TableCell>

                  <TableCell sx={tableStyles.tableCell}>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {hospitals?.map((hospital) => (
                  <TableRow
                    key={hospital.id}
                    sx={{
                      "& >td": {
                        p: "0.5rem",
                      },
                    }}
                  >
                    <TableCell
                      sx={{
                        fontWeight: 500,
                        color: "var(--neuro-table-head-primary)",
                        fontFamily: "var(--neuro-font-family-roboto-condensed)",
                        fontSize: "14px",
                      }}
                    >
                      {capitalizeFirstLetter(hospital.name || "")}
                    </TableCell>

                    <TableCell sx={tableStyles.subTableCell}>
                      {fullHospitalAddess(
                        hospital?.city,
                        hospital.state,
                        hospital.country
                      )}
                    </TableCell>

                    <TableCell sx={tableStyles.subTableCell}>
                      {showContactNumber(hospital?.contactNumber || "")}
                    </TableCell>

                    <TableCell sx={tableStyles.subTableCell}>
                      {hospital.email || "-"}
                    </TableCell>

                    <TableCell sx={tableStyles.subTableCell}>
                      {getFormattedDate(hospital?.createdAt || "-")}
                    </TableCell>

                    <TableCell sx={tableStyles.subTableCell}>
                      <Box display="flex" justifyContent="space-around">
                        <IconButton
                          onClick={() => handleEdit(hospital)}
                          color="secondary"
                          disableRipple
                          sx={{
                            p: 0,
                            "&:hover": { backgroundColor: "transparent" },
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDelete(hospital.id)}
                          color="primary"
                          disableRipple
                          sx={{
                            p: 0,
                            "&:hover": { backgroundColor: "transparent" },
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <ContentCenter>
            <Typography className="no-data">No clinics found</Typography>
          </ContentCenter>
        )}

        <AddEditHospitalModal
          open={dialogOpen}
          onClose={handleCloseDialog}
          hospital={editHospital}
          loadHospitals={loadHospitals}
        />
        <ConfirmDialog
          open={confirmOpen}
          title="Confirm Delete"
          message="Are you sure you want to delete this hospital?"
          onClose={handleConfirmClose}
        />
      </Box>
    </>
  );
};

export default HospitalsList;
