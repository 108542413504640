import React, { useEffect, useState } from "react";
import useAppointmentStore from "../../../store/appointmentsStore";
import { AllAppointmentsProps } from "../../../types/appointments";
import NewAppointmentList from "./NewAppointmentList";

const AllAppointments: React.FC<AllAppointmentsProps> = ({
  patientId,
  from = "",
}) => {
  // props & state values
  const {
    sortedAppointments,
    dashboardSortedAppointments,
    upcomingAppointments,
    ongoingAppointments,
    completedAppointments,
    cancelledAppointments,
    loadingFetchAppointments,
    errorFetchAppointments,
    fetchAppointments,
    cleanUpAppointments,
  } = useAppointmentStore();

  const [loading, setLoading] = useState(true);

  // callbacks & functions
  useEffect(() => {
    if (patientId) {
      if (from === "dashboard") {
        setLoading(false);
        fetchAppointments(patientId, "DASHBOARD");
      } else {
        setLoading(false);
        fetchAppointments(patientId);
      }
    } else {
      if (from === "dashboard") {
        setLoading(false);
        fetchAppointments("", "DASHBOARD");
      } else {
        setLoading(false);
        fetchAppointments();
      }
    }

    return () => {
      cleanUpAppointments();
    };
  }, []);

  return (
    <NewAppointmentList
      appointments={
        from === "dashboard" ? dashboardSortedAppointments : sortedAppointments
      }
      upcomingAppointments={upcomingAppointments}
      ongoingAppointments={ongoingAppointments}
      completedAppointments={completedAppointments}
      cancelledAppointments={cancelledAppointments}
      loadingFetchAppointments={loadingFetchAppointments || loading}
      errorFetchAppointments={errorFetchAppointments}
      from={from}
      patientId={patientId}
    />
  );
};

export default AllAppointments;
