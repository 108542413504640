import { Typography } from "@mui/material";
import { isAdmin, isPatient } from "../../../utils/auth";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";

const DoctorName = ({
  id,
  name,
  from = "",
  noMarginTop = false,
}: {
  id: string;
  name: string;
  from?: string;
  noMarginTop?: boolean;
}) => {
  // props & state value
  const navigate = useNavigate();
  const { userRole } = useAuthStore();
  const isPatientOrAdmin = isPatient(userRole) || isAdmin(userRole);

  return (
    <Typography
      sx={{
        marginTop: noMarginTop ? 0 : "0.75rem",
        fontWeight: "var(--neuro-font-weight-bold)",
        color: "var(--neuro-bg-darkblue-primary)",
        fontFamily: "var(--neuro-font-family-roboto)",
        lineHeight: "var(--neuro-line-height-tiny-plus)",
        "&:hover": {
          color:
            from === "meet"
              ? "var(--neuro-bg-darkblue-primary)"
              : "var(--neuro-button-bg-primary)",
        },
      }}
      onClick={(event) => {
        if (from === "meet") {
          return;
        } else {
          event.stopPropagation();
          if (isPatientOrAdmin) {
            navigate(`/doctors/${id || ""}/details`);
          }
        }
      }}
    >
      {name}
    </Typography>
  );
};

export default DoctorName;
