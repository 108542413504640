import React, { useState, useEffect } from "react";
import { fetchPatients } from "../../services/patientService";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Patient } from "../../types/common";
import { useLocation, useNavigate } from "react-router-dom";
import ComponentHeader from "../shared/ComponentHeader";
import HorizontalTabs from "../profile/patient/HorizontalTabs";
import { capitalizeFirstLetter } from "../../utils/common";
import AddIcon from "@mui/icons-material/Add";
import PatientSignUpModal from "./PatientSignUpModal";
import Button from "../ui/Button";
import LoadingScreen from "../shared/LoadingScreen";
import PatientsList from "./PatientsList";
import ContentCenter from "../shared/ContentCenter";
import PatientTabs from "./PatientTabs";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";

const Patients: React.FC = () => {
  // props & state values
  const isMobile = useMediaQuery("(max-width:37.5rem)");

  const [patients, setPatients] = useState<Patient[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedPatient, setSelectedPatient] = useState<any>(null);

  console.log("selectedPatient", selectedPatient);

  const [addNewPatientModalOpen, setAddNewPatientModalOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    "Profile",
    "Documents",
    "Appointments",
    "Forms",
    "ABHA Details",
    "Audit Logs",
  ];

  const [selectedTab, setSelectedTab] = useState<string>("Profile");
  const [showProfileEdit, setShowProfileEdit] = useState<boolean>(false);

  const showPatientsList = location?.pathname === "/patients";

  const breadcrumbItems = [
    { label: "Patients", href: "/patients" },
    {
      label: `${capitalizeFirstLetter(selectedPatient?.user?.firstName || "")}`,
    },
    { label: selectedTab },
  ];

  // callbacks & functions
  function getInitialTab() {
    if (location.pathname.toLowerCase().includes("abha-details")) {
      return "ABHA Details";
    }

    if (location.pathname.toLowerCase().includes("audit-logs")) {
      return "Audit Logs";
    }
    const foundTab = tabs.find((tab) =>
      location.pathname.toLowerCase().includes(tab.toLowerCase())
    );
    return foundTab || "Profile";
  }

  useEffect(() => {
    const newTab = getInitialTab();
    setSelectedTab(newTab);
  }, [location.pathname]);

  const onTabClick = (tab: string) => {
    setSelectedTab(tab);
    if (selectedPatient) {
      if (tab === "ABHA Details") {
        navigate(`/patients/${selectedPatient.id}/abha-details`);
      } else if (tab === "Audit Logs") {
        console.log("im here");
        navigate(`/patients/${selectedPatient.id}/audit-logs`);
      } else {
        navigate(`/patients/${selectedPatient.id}/${tab.toLowerCase()}`);
      }
    }
  };

  const handleProfileViewEdit = () => {
    setShowProfileEdit((prev) => !prev);
  };

  useEffect(() => {
    return () => {
      setSelectedPatient(null);
    };
  }, []);

  useEffect(() => {
    loadPatients();
  }, [location?.pathname]);

  const loadPatients = async () => {
    try {
      const response = await fetchPatients();
      setPatients(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching patients:", error);
      setLoading(false);
    }
  };

  const handleAddNew = () => {
    setAddNewPatientModalOpen(true);
  };

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        height: "100%",
        paddingBlock: "1rem",
        paddingInline: "0.875rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {showPatientsList && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <ComponentHeader title="Patient List" />

            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              startIcon={<AddIcon />}
              onClick={handleAddNew}
            >
              Add New
            </Button>
          </Box>
        )}

        {!showPatientsList && <CustomBreadcrumbs items={breadcrumbItems} />}

        {!showPatientsList && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: isMobile ? "flex-start" : "center",
              gap: "0.625rem",
            }}
          >
            <HorizontalTabs
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onClick={onTabClick}
              from="admin"
            />

            {selectedTab === "Profile" && (
              <Button
                variant="outlined"
                color="secondary"
                className="outlined-secondary-button"
                onClick={handleProfileViewEdit}
              >
                {showProfileEdit ? "View Profile" : "Edit Profile"}
              </Button>
            )}
          </Box>
        )}
      </Box>

      {!showPatientsList && (
        <PatientTabs
          selectedPatient={selectedPatient}
          selectedTab={selectedTab}
          from="admin"
          showProfileEdit={showProfileEdit}
        />
      )}

      {showPatientsList && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <>
            {loading && <LoadingScreen />}

            {!loading && patients && patients.length === 0 && (
              <ContentCenter>
                <Typography className="no-data">No patients found</Typography>
              </ContentCenter>
            )}

            {!loading && patients && patients.length > 0 && (
              <PatientsList
                patients={patients}
                selectedPatient={selectedPatient}
                setSelectedPatient={setSelectedPatient}
              />
            )}

            {/* new patient add modal */}
            <PatientSignUpModal
              open={addNewPatientModalOpen}
              onClose={() => setAddNewPatientModalOpen(false)}
              loadPatients={loadPatients}
            />
          </>
        </Box>
      )}
    </Box>
  );
};

export default Patients;
