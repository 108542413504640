// third-party imports
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

//project imports
import Button from "../../ui/Button";
import Conference from "./Conference";
import DoctorsNote from "./DoctorsNote";
import GeneralDetails from "./GeneralDetails";
import Medication from "./Medication";
import PatientHeader from "./PatientHeader";
import { useEffect, useState } from "react";
import useAuthStore from "../../../store/authStore";
import { isAdmin, isDoctor } from "../../../utils/auth";
import LoadingScreen from "../../shared/LoadingScreen";
import ContentCenter from "../../shared/ContentCenter";
import { capitalizeWordsPreservingSpaces } from "../../../utils/common";
import useAppStore from "../../../store/appStore";
import {
  getConsultationSummary,
  postConsultationSummary,
} from "../../../services/appointmentsService";
import useAppointmentStore from "../../../store/appointmentsStore";
// import { uploadRecording } from "../../../services/documentsService";
import ConfirmModal from "../../shared/ConfirmModal";
import { ONLINE } from "../../../utils/constants";

const Meet = () => {
  // props & state values
  const { appointmentId } = useParams();
  const location = useLocation();
  const { appointmentMode } = location.state || {};
  const navigate = useNavigate();

  const { medicines } = useAppointmentStore();

  const {
    setHelpModalOpen,
    setIsMenuCollapsed,
    setShowHeader,
    setShowSidebar,
    showSnackbar,
  } = useAppStore();

  const [loading, setLoading] = useState<boolean>(true);
  const [createSummaryLoading, setCreateSummaryLoading] =
    useState<boolean>(false);

  const [summaryDetails, setSummaryDetails] = useState<any>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  console.log("summaryDetails", summaryDetails);

  const { userRole } = useAuthStore();

  // callbacks & functions
  const fetchConsultationSummary = async (id: string) => {
    try {
      const response = await getConsultationSummary(id);
      setSummaryDetails(response?.data || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const createSummary = async (data: any, saveType?: string) => {
    setCreateSummaryLoading(true);
    showSnackbar("Updating medications, please wait", "info");
    let params;
    if (saveType) {
      params = {
        saveType,
      };
    } else {
      params = undefined;
    }

    try {
      const response = await postConsultationSummary(data, params);
      if (response?.data) {
        showSnackbar("Medications updated successfully", "success");
        // await uploadRecording(
        //   summaryDetails?.patientGeneralDetails?.id || "",
        //   appointmentId || ""
        // );
      }
      setCreateSummaryLoading(false);
    } catch (error) {
      showSnackbar("Could not update medications", "error");
      setCreateSummaryLoading(false);
    }
  };

  const handleConfirm = async () => {
    const navigation = () => {
      if (isAdmin(userRole)) {
        navigate(
          `/patients/${summaryDetails?.patientGeneralDetails?.id || ""}/appointments`
        );
      } else {
        navigate("/appointments");
      }
    };

    try {
      const data = {
        appointmentId: appointmentId,
      };
      await postConsultationSummary(data);
      navigation();
    } catch (error) {
      navigation();
      console.error("error", error);
    }
  };

  const handleCancel = () => {
    setOpenConfirmModal(false);
  };

  useEffect(() => {
    setIsMenuCollapsed(true);
    setShowHeader(false);
    setShowSidebar(false);
    setHelpModalOpen(false);

    return () => {
      setShowHeader(true);
      setShowSidebar(true);
      setHelpModalOpen(true);
    };
  }, []);

  useEffect(() => {
    if (appointmentId) {
      fetchConsultationSummary(appointmentId);
    }
  }, [appointmentId]);

  const handleCreateSummary = () => {
    const medicineData =
      medicines && medicines.length > 0
        ? medicines
            .filter((medicine) => {
              // Check that all required fields have values
              return (
                medicine.medication.trim() !== "" &&
                medicine.medicationType.trim() !== "" &&
                String(medicine.dosageAmount).trim() !== "" &&
                medicine.dosageUnit.trim() !== "" &&
                String(medicine.durationAmount).trim() !== "" &&
                medicine.durationUnit.trim() !== "" &&
                medicine.frequency.trim() !== ""
              );
            })
            .map((medicine) => ({
              ...medicine,
              dosageAmount: Number(medicine.dosageAmount),
              durationAmount: Number(medicine.durationAmount),
            }))
        : [];

    const data = {
      appointmentId: appointmentId,
      medications: medicineData,
    };

    if (appointmentId) {
      createSummary(data, "MEDICATIONS");
    }
  };

  return (
    <Box
      sx={{
        // paddingBlock: "28px",
        // paddingInline: "20px",
        width: "100vw",
        height: "100vh", // Ensure it takes full viewport height
        overflow: "hidden", // Prevent any potential overflow
      }}
    >
      <Grid container sx={{ height: "100vh", overflow: "hidden" }}>
        {appointmentMode && appointmentMode === ONLINE && (
          <Grid
            item
            xs={12}
            // md={6}
            md={isDoctor(userRole) ? 6 : 12}
            sx={{ height: "100vh", overflow: "hidden" }} // Ensure full height
          >
            <Conference appointmentId={appointmentId || ""} />
          </Grid>
        )}

        {isDoctor(userRole) && (
          <Grid
            item
            xs={12}
            md={appointmentMode && appointmentMode === ONLINE ? 6 : 12}
            sx={{
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              backgroundColor: "var(--neuro-white-text)",
              borderLeft: "1px solid var(--neuro-black-text)",
            }}
          >
            {loading && <LoadingScreen />}

            {!loading && !summaryDetails && (
              <ContentCenter>
                <Typography className="no-data">Details not found</Typography>
              </ContentCenter>
            )}

            {!loading && summaryDetails && (
              <>
                <PatientHeader
                  name={
                    capitalizeWordsPreservingSpaces(
                      summaryDetails?.patientGeneralDetails?.name || ""
                    ) || ""
                  }
                />
                <Box
                  sx={{
                    paddingInline: "0.938rem",
                    paddingTop: "1.125rem",
                    paddingBottom: "6.25rem",
                    height: "calc(100% - 5rem)",
                    overflowY: "auto",
                  }}
                >
                  <GeneralDetails
                    patientDetails={summaryDetails?.patientGeneralDetails}
                  />

                  <DoctorsNote
                    appointmentId={appointmentId}
                    doctorNotes={summaryDetails?.doctorNotes}
                  />

                  <Medication
                    appointmentId={appointmentId}
                    medications={summaryDetails?.prescription}
                  />

                  <Box
                    sx={{
                      marginTop: "6.25rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      className="primary-button"
                      onClick={handleCreateSummary}
                      disabled={createSummaryLoading}
                      sx={{ marginRight: "0.875rem" }}
                    >
                      Save Prescription
                    </Button>

                    <Button
                      variant="outlined"
                      className="outlined-secondary-button"
                      onClick={() => setOpenConfirmModal(true)}
                    >
                      Close Meeting
                    </Button>

                    <ConfirmModal
                      open={openConfirmModal}
                      description="Are you sure, you want to close the meeting?"
                      handleConfirm={handleConfirm}
                      handleCancel={handleCancel}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Meet;
