import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import { format } from "date-fns";
import { getAllAuditLogsOfPatient } from "../../services/auditLogsService";
import { PatientAuditLogsListProps } from "../../types/patients";

const PatientFormsList: React.FC<PatientAuditLogsListProps> = ({
  patientId,
}) => {
  // props & state values
  const [auditLogs, setAuditLogs] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const styles = {
    tableCell: {
      fontWeight: "var(--neuro-font-weight-bold)",
      color: "var(--neuro-table-head-primary)",
      fontFamily: "var(--neuro-font-family-roboto-condensed)",
    },
    subTableCell: {
      fontWeight: "var(--neuro-font-weight-regular)",
      color: "var(--neuro-table-head-primary)",
      fontFamily: "var(--neuro-font-family-roboto-condensed)",
    },
  };

  const formattedDate = (dateString: string | null) => {
    if (!dateString || dateString === "") {
      return "";
    }
    const formatedDate = format(new Date(dateString), "d MMM yyyy, hh:mm a");

    return formatedDate;
  };

  // callbacks & functions
  const loadAuidtLogs = async (id: string) => {
    try {
      const response = await getAllAuditLogsOfPatient(id);
      setAuditLogs(response?.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching referrals:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (patientId) {
      loadAuidtLogs(patientId);
    }
  }, [patientId]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {loading && <LoadingScreen />}

      {!loading && auditLogs && auditLogs.length === 0 && (
        <ContentCenter>
          <Typography className="no-data">Audit Logs not found</Typography>
        </ContentCenter>
      )}

      {!loading && auditLogs && auditLogs.length > 0 && (
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            border: "0.063rem solid var(--neuro-secondary_border)",
            borderBottom: "none",
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow
                sx={{
                  "& >th": {
                    p: "0.5rem",
                  },
                }}
              >
                <TableCell sx={styles.tableCell}>Timestamp</TableCell>

                <TableCell sx={styles.tableCell}>User</TableCell>

                <TableCell sx={styles.tableCell}>Action Type</TableCell>

                <TableCell sx={styles.tableCell}>Description</TableCell>

                <TableCell sx={styles.tableCell}>Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {auditLogs?.map((auditLog) => (
                <TableRow
                  key={auditLog?.id}
                  sx={{
                    "& >td": {
                      p: "0.5rem",
                    },
                  }}
                >
                  <TableCell sx={styles.subTableCell}>
                    {formattedDate(auditLog?.timestamp || "")}
                  </TableCell>

                  <TableCell sx={styles.subTableCell}>
                    {auditLog?.userName} {`(${auditLog?.userType})`}
                    {/* {auditLog?.userName} <br />
                    {`(${auditLog?.userType})`} */}
                  </TableCell>

                  <TableCell sx={styles.subTableCell}>
                    {auditLog?.eventType}
                  </TableCell>

                  <TableCell sx={styles.subTableCell}>
                    {auditLog?.description}
                  </TableCell>

                  <TableCell sx={styles.subTableCell}>
                    {auditLog?.success ? "SUCCESS" : "FAIL"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default PatientFormsList;
