import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../ui/Button";
import Drawer from "../shared/Drawer";
import GeneralForm from "../forms/GeneralForm";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import { format } from "date-fns";
import { capitalizeFirstLetter } from "../../utils/common";
import FormFilledDetails from "../forms/FormFilledDetails";
import useAuthStore from "../../store/authStore";
import { isPatient } from "../../utils/auth";
import assignFormStore from "../../store/formStore";
import { fetchFormRequestsApi } from "../../services/formService";
import useAppStore from "../../store/appStore";
import { PatientFormsListProps } from "../../types/patients";

const PatientFormsList: React.FC<PatientFormsListProps> = ({
  patientId,
  patientName,
}) => {
  // props & state values
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedForm, setSelectedForm] = useState<any>(null);
  const [formAction, setFormAction] = useState<string | null>(null);

  const [formRequests, setFormRequests] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const isMobile = useMediaQuery("(max-width:37.5rem)");

  const { showSnackbar } = useAppStore();

  const { userRole } = useAuthStore();

  const styles = {
    tableCell: {
      fontWeight: "var(--neuro-font-weight-bold)",
      color: "var(--neuro-table-head-primary)",
      fontFamily: "var(--neuro-font-family-roboto-condensed)",
    },
    subTableCell: {
      fontWeight: "var(--neuro-font-weight-regular)",
      color: "var(--neuro-table-head-primary)",
      fontFamily: "var(--neuro-font-family-roboto-condensed)",
    },
  };

  const {
    formSubmitted,
    formAssigned,
    // assignForm,
    submitLoading,
    submitError,
    submitFormResponse,
    cleanUpFormSubmittedState,
  } = assignFormStore();

  // callbacks & functions
  const loadFormRequests = async (id: string) => {
    try {
      const response = await fetchFormRequestsApi(id);
      setFormRequests(response?.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching referrals:", error);
      setLoading(false);
    }
  };

  const onSubmitForm = (responses: any, score: number) => {
    const data = {
      responses,
      score,
    };
    submitFormResponse(selectedForm?.id, data);
  };

  useEffect(() => {
    if (patientId) {
      loadFormRequests(patientId);
    }
  }, [patientId]);

  useEffect(() => {
    if (formSubmitted && patientId) {
      loadFormRequests(patientId);
    }
  }, [formSubmitted, patientId]);

  useEffect(() => {
    if (formAssigned && patientId) {
      loadFormRequests(patientId);
    }
  }, [formAssigned, patientId]);

  useEffect(() => {
    if (submitError) {
      showSnackbar("Could not submit form, please try again", "error");
      cleanUpFormSubmittedState();
    }
    if (submitLoading) {
      showSnackbar("Form is submitting, please try again", "info");
    }

    if (formSubmitted) {
      showSnackbar("Form is submittied", "success");
    }
  }, [formSubmitted, submitLoading, submitError]);

  const onCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedForm(null);
    setFormAction(null);
    cleanUpFormSubmittedState();
  };

  const formattedDate = (dateString: string | null) => {
    if (!dateString || dateString === "") {
      return "";
    }
    const formatedDate = format(new Date(dateString), "d MMMM yyyy");

    return formatedDate;
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {loading && <LoadingScreen />}

      {!loading && formRequests && formRequests.length === 0 && (
        <ContentCenter>
          <Typography className="no-data">Forms not found</Typography>
        </ContentCenter>
      )}

      {!loading && formRequests && formRequests.length > 0 && (
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            border: "0.063rem solid var(--neuro-secondary_border)",
            borderBottom: "none",
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow
                sx={{
                  "& >th": {
                    p: "0.5rem",
                  },
                }}
              >
                <TableCell sx={styles.tableCell}>Form Name</TableCell>

                <TableCell sx={styles.tableCell}>Assigned By</TableCell>

                <TableCell sx={styles.tableCell}>Assigned On</TableCell>

                <TableCell sx={styles.tableCell}>Status</TableCell>

                {!isPatient(userRole) && (
                  <TableCell sx={styles.tableCell}>Score</TableCell>
                )}

                <TableCell sx={styles.tableCell}>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {formRequests?.map((form) => (
                <TableRow
                  key={form?.id}
                  sx={{
                    "& >td": {
                      p: "0.5rem",
                    },
                  }}
                >
                  <TableCell sx={styles.subTableCell}>
                    {form?.form?.name}
                  </TableCell>

                  <TableCell sx={styles.subTableCell}>
                    {capitalizeFirstLetter(form?.assignedBy?.firstName || "") ||
                      ""}{" "}
                  </TableCell>

                  <TableCell sx={styles.subTableCell}>
                    {formattedDate(form?.createdAt || "")}
                  </TableCell>

                  <TableCell sx={styles.subTableCell}>{form?.status}</TableCell>

                  {!isPatient(userRole) && (
                    <TableCell sx={styles.subTableCell}>
                      {form?.responses?.isScoreBased
                        ? form?.score === 0
                          ? 0
                          : form?.score || "-"
                        : "-"}
                    </TableCell>
                  )}

                  <TableCell sx={styles.subTableCell}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <>
                        {form?.status === "COMPLETED" && (
                          <>
                            <Button
                              variant="outlined"
                              size="small"
                              className="outlined-secondary-button"
                              onClick={() => {
                                setSelectedForm(form);
                                setFormAction("Edit");
                                setDrawerOpen(true);
                              }}
                              sx={{
                                minHeight: "1.375rem",
                                maxHeight: "1.375rem",
                              }}
                            >
                              Edit
                            </Button>
                            <Box sx={{ height: "1.875rem" }}>
                              <Typography
                                sx={{
                                  fontSize: "var(--neuro-font-size-tiny-plus)",
                                  lineHeight: "120%",
                                  color: "var(--neuro-secondary_border)",
                                }}
                              >
                                /
                              </Typography>
                            </Box>
                            <Button
                              variant="outlined"
                              size="small"
                              className="outlined-secondary-button"
                              onClick={() => {
                                setSelectedForm(form);
                                setFormAction("View");
                                setDrawerOpen(true);
                              }}
                              sx={{
                                minHeight: "1.375rem",
                                maxHeight: "1.375rem",
                              }}
                            >
                              View
                            </Button>
                          </>
                        )}

                        {form?.status === "PENDING" && (
                          <Button
                            size="small"
                            variant="outlined"
                            className="outlined-secondary-button"
                            onClick={() => {
                              setSelectedForm(form);
                              setFormAction("Fill");
                              setDrawerOpen(true);
                            }}
                            sx={{
                              minHeight: "1.375rem",
                              maxHeight: "1.375rem",
                            }}
                          >
                            Fill
                          </Button>
                        )}
                      </>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* view/edit form drawer */}
      <Drawer
        open={drawerOpen}
        width={isMobile ? "100vw" : "45vw"}
        headerText={`${patientName}`}
        onClose={() => {
          setSelectedForm(null);
          setFormAction(null);
          setDrawerOpen(false);
          cleanUpFormSubmittedState();
        }}
      >
        <Box
          sx={{
            paddingInline: "1rem",
            paddingTop: "1.25rem",
            height: "calc(100% - 3.75rem)",
            overflowY: "auto",
          }}
        >
          <>
            {selectedForm && (
              <>
                {formSubmitted ? (
                  <FormFilledDetails
                    score={
                      selectedForm?.form?.isScoreBased &&
                      formSubmitted?.score !== null &&
                      formSubmitted?.score !== undefined
                        ? formSubmitted?.score
                        : null
                    }
                    patientName={patientName}
                    onClose={onCloseDrawer}
                  />
                ) : (
                  <GeneralForm
                    formSchema={
                      selectedForm?.status === "COMPLETED"
                        ? selectedForm?.responses
                        : selectedForm?.form?.questions || null
                    }
                    readOnly={formAction === "View" ? true : false}
                    onFinish={onSubmitForm}
                    loading={submitLoading}
                    patientName={patientName}
                    onClosing={
                      formAction === "View" ? onCloseDrawer : undefined
                    }
                    isEdit={formAction === "Edit" ? true : false}
                  />
                )}
              </>
            )}
          </>
        </Box>
      </Drawer>
    </Box>
  );
};

export default PatientFormsList;
