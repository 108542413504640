import React from "react";
import { TabAppointmentsProps } from "../../../types/appointments";
import LoadingScreen from "../../shared/LoadingScreen";
import ContentCenter from "../../shared/ContentCenter";
import { Typography } from "@mui/material";
import AppointmentCard from "./AppointmentCard";

const TabAppointments: React.FC<TabAppointmentsProps> = ({
  loadingFetchAppointments,
  errorFetchAppointments,
  appointments,
  patientId,
  from = "",
}) => {
  return (
    <>
      {loadingFetchAppointments && <LoadingScreen />}

      {errorFetchAppointments && (
        <ContentCenter>
          <Typography className="no-data">Appointments not found</Typography>
        </ContentCenter>
      )}

      {!loadingFetchAppointments &&
        !errorFetchAppointments &&
        appointments &&
        appointments.length === 0 && (
          <ContentCenter>
            <Typography className="no-data">Appointments not found</Typography>
          </ContentCenter>
        )}

      {!loadingFetchAppointments &&
        !errorFetchAppointments &&
        appointments &&
        appointments.length > 0 &&
        appointments.map((appointment) => (
          <AppointmentCard
            key={appointment.id}
            {...appointment}
            sortedAppointments={appointments}
            patientId={patientId}
            usedIn={from}
            from={from}
          />
        ))}
    </>
  );
};

export default TabAppointments;
