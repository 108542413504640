import React, { useState, useEffect } from "react";
import { fetchHospitals } from "../../services/hospitalService";
import { Box } from "@mui/material";
import { Hospital } from "../../types/common";
import HospitalsList from "./HospitalsList";

const Hospitals: React.FC = () => {
  const [hospitals, setHospitals] = useState<Hospital[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    loadHospitals();
  }, []);

  const loadHospitals = async () => {
    try {
      const response = await fetchHospitals();
      setHospitals(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching hospitals:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          height: "100%",
          paddingBlock: "1rem",
          paddingInline: "0.875rem",
          overflow: "auto",
        }}
      >
        <HospitalsList
          hospitals={hospitals}
          loading={loading}
          loadHospitals={loadHospitals}
        />
      </Box>
    </>
  );
};

export default Hospitals;
