import React, { useState } from "react";
import {
  deleteReferral,
  updateReferralStatus,
} from "../../services/referralService";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddEditReferralModal from "./AddEditReferralModal";
import ConfirmDialog from "../shared/ConfirmDialog";
import CommentModal from "./CommentModal";
import { Referral } from "../../types/common";
import useAuthStore from "../../store/authStore"; // Assuming this is the correct path
import ComponentHeader from "../shared/ComponentHeader";
import ContentCenter from "../shared/ContentCenter";
import LoadingScreen from "../shared/LoadingScreen";
import { ReferralsListProps } from "../../types/referrals";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/common";
import Button from "../ui/Button";
import FilterByAppointments from "../appointments/Appointments/FilterByAppointments";
import { Option } from "../../types/appointments";
import FilterByMobileDropdown from "../shared/FilterByMobileDropdown";
import { NETWORK } from "../../utils/constants";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { tableStyles } from "../../utils/styles";
import useAppStore from "../../store/appStore";
// import DashboardFilter from "../shared/DashboardFilter";

const ReferralsList: React.FC<ReferralsListProps> = ({
  referrals,
  loading,
  loadReferrals,
}) => {
  // props & state values
  const isMobile = useMediaQuery("(max-width:37.5rem)");

  const { selectedRefferalsFilter, setSelectedRefferalsFilter } = useAppStore();

  console.log("referrals data", referrals);

  const { userRole } = useAuthStore();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);
  const [commentStatus, setCommentStatus] = useState<"Accepted" | "Rejected">(
    "Accepted"
  );

  const [open, setOpen] = useState(false);

  const options: Option[] = [
    { value: "All", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Cancelled" },
  ];

  const [selectedReferral, setSelectedReferral] = useState<Referral | null>(
    null
  );
  const [deleteReferralId, setDeleteReferralId] = useState<string | null>(null);
  const [editReferral, setEditReferral] = useState<Referral | null>(null);

  // callbacks & functions
  const getStatusIcon = (status: string) => {
    if (status === "Accepted") {
      return (
        <CheckCircleIcon sx={{ color: "var(--neuro-button-bg-success)" }} />
      );
    } else if (status === "Pending") {
      return <PendingIcon sx={{ color: "var(--neuro-button-bg-primary)" }} />;
    } else if (status === "Rejected") {
      return <NotInterestedIcon sx={{ color: "var(--neuro-svg-error)" }} />;
    } else {
      return <PendingIcon sx={{ color: "var(--neuro-button-bg-primary)" }} />;
    }
  };

  const showContactNumber = (number: string | null | undefined) => {
    if (!number || number === "") {
      return "-";
    } else {
      if (number.includes("+")) {
        return number;
      } else {
        return `+91${number}`;
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleAddNew = () => {
    setEditReferral(null);
    setDialogOpen(true);
  };

  const handleEdit = (referral: Referral) => {
    setEditReferral(referral);
    setDialogOpen(true);
  };

  const handleDelete = async (id: string) => {
    setDeleteReferralId(id);
    setConfirmOpen(true);
  };

  const handleConfirmClose = async (confirmed: boolean) => {
    setConfirmOpen(false);
    if (confirmed && deleteReferralId) {
      try {
        await deleteReferral(deleteReferralId);
        loadReferrals();
      } catch (error) {
        console.error("Error deleting referral:", error);
      }
    }
    setDeleteReferralId(null);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    loadReferrals();
  };

  const handleCommentModalClose = async (comments: string | null) => {
    if (selectedReferral) {
      try {
        await updateReferralStatus(
          selectedReferral.id,
          commentStatus,
          comments
        );
        loadReferrals();
      } catch (error) {
        console.error(`Error updating referral to ${commentStatus}:`, error);
      }
    }
    setCommentModalOpen(false);
    setSelectedReferral(null);
  };

  const openCommentModal = (
    referral: Referral,
    status: "Accepted" | "Rejected"
  ) => {
    setSelectedReferral(referral);
    setCommentStatus(status);
    setCommentModalOpen(true);
  };

  const filteredReferrals = referrals?.filter(
    (referral) => referral.status === selectedRefferalsFilter
  );

  const AllRefferalsTable = () => {
    return referrals?.length > 0 ? (
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          border: "0.063rem solid var(--neuro-secondary_border)",
          borderBottom: "none",
        }}
      >
        <Table>
          {
            <TableHead>
              <TableRow
                sx={{
                  "& >th": {
                    p: "0.5rem",
                  },
                }}
              >
                <TableCell sx={tableStyles.tableCell}>Patient Name</TableCell>

                <TableCell sx={tableStyles.tableCell}>Mobile</TableCell>

                <TableCell sx={tableStyles.tableCell}>Address</TableCell>

                {/* <TableCell sx={tableStyles.tableCell}>Urgency</TableCell> */}

                <TableCell sx={tableStyles.tableCell}>Referred To</TableCell>

                <TableCell sx={tableStyles.tableCell}>Referred By</TableCell>

                <TableCell sx={tableStyles.tableCell}>Registered On</TableCell>

                <TableCell sx={tableStyles.tableCell}>Status</TableCell>

                {userRole === "ADMIN" &&
                  referrals.some(
                    (referral) => referral?.status === "Pending"
                  ) && (
                    <TableCell sx={tableStyles.tableCell}>Comments</TableCell>
                  )}

                <TableCell sx={tableStyles.tableCell}>
                  {userRole === "ADMIN" &&
                  referrals.some((referral) => referral?.status === "Pending")
                    ? "Actions"
                    : "Comments"}
                </TableCell>
              </TableRow>
            </TableHead>
          }

          <TableBody>
            {referrals?.map((referral) => (
              <TableRow
                key={referral.id}
                sx={{
                  "& >td": {
                    p: "0.5rem",
                  },
                }}
              >
                <TableCell
                  sx={{
                    fontWeight: 500,
                    color: "var(--neuro-table-head-primary)",
                    fontFamily: "var(--neuro-font-family-roboto-condensed)",
                    fontSize: "14px",
                  }}
                >{`${capitalizeFirstLetter(referral.firstName || "") || "-"}`}</TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {showContactNumber(referral.mobile || "")}
                </TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {referral.city && referral.city !== ""
                    ? `${referral.city},`
                    : ""}{" "}
                  {referral.state || ""}
                </TableCell>

                {/* <TableCell sx={tableStyles.subTableCell}>
                  {referral.urgencyOfReferral || "-"}
                </TableCell> */}

                <TableCell
                  sx={tableStyles.subTableCell}
                >{`${referral.referralDoctorFirstName || "-"}`}</TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {referral?.doctor?.user?.firstName || "-"}{" "}
                </TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {getFormattedDate(referral?.createdAt || "-")}
                </TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {getStatusIcon(referral?.status || "")}
                </TableCell>

                {userRole === "ADMIN" &&
                  referrals.some(
                    (referral) => referral?.status === "Pending"
                  ) && (
                    <TableCell sx={tableStyles.subTableCell}>
                      {referral?.status === "Pending" ? (
                        <>-</>
                      ) : (
                        referral?.comments || "-"
                      )}
                    </TableCell>
                  )}

                <TableCell sx={tableStyles.subTableCell}>
                  {userRole === "DOCTOR" && referral?.status === "Pending" && (
                    <Box display="flex" justifyContent="space-around">
                      <IconButton
                        onClick={() => handleEdit(referral)}
                        color="secondary"
                        disableRipple
                        sx={{
                          p: 0,
                          "&:hover": { backgroundColor: "transparent" },
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(referral.id)}
                        color="primary"
                        disableRipple
                        sx={{
                          p: 0,
                          "&:hover": { backgroundColor: "transparent" },
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}

                  {userRole === "DOCTOR" && referral?.status !== "Pending" && (
                    <>{referral?.comments || "-"}</>
                  )}

                  {userRole === "ADMIN" && referral?.status === "Pending" && (
                    <Box display="flex" gap={2.5}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="primary-button "
                        onClick={() => openCommentModal(referral, "Accepted")}
                        size="small"
                        sx={{ minHeight: "1.375rem", maxHeight: "1.375rem" }}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        className="warning-button"
                        onClick={() => openCommentModal(referral, "Rejected")}
                        size="small"
                        sx={{ minHeight: "1.375rem", maxHeight: "1.375rem" }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}

                  {userRole === "ADMIN" &&
                    referrals.some(
                      (referral) => referral?.status === "Pending"
                    ) &&
                    referral?.status !== "Pending" && <>-</>}

                  {userRole === "ADMIN" &&
                    !referrals.some(
                      (referral) => referral?.status === "Pending"
                    ) && <>{referral?.comments || "-"}</>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <ContentCenter>
        <Typography className="no-data">No Referrals found</Typography>
      </ContentCenter>
    );
  };

  const FilteredRefferalsTable = () => {
    return filteredReferrals?.length > 0 ? (
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          border: "0.063rem solid var(--neuro-secondary_border)",
          borderBottom: "none",
        }}
      >
        <Table>
          {
            <TableHead>
              <TableRow
                sx={{
                  "& >th": {
                    p: "0.5rem",
                  },
                }}
              >
                <TableCell sx={tableStyles.tableCell}>Patient Name</TableCell>

                <TableCell sx={tableStyles.tableCell}>Mobile</TableCell>

                <TableCell sx={tableStyles.tableCell}>Address</TableCell>

                <TableCell sx={tableStyles.tableCell}>Urgency</TableCell>

                <TableCell sx={tableStyles.tableCell}>Referred To</TableCell>

                <TableCell sx={tableStyles.tableCell}>Referred By</TableCell>

                <TableCell sx={tableStyles.tableCell}>Registered On</TableCell>

                <TableCell sx={tableStyles.tableCell}>Status</TableCell>

                <TableCell sx={tableStyles.tableCell}>
                  {userRole === "ADMIN" && selectedRefferalsFilter === "Pending"
                    ? "Actions"
                    : "Comments"}
                </TableCell>
              </TableRow>
            </TableHead>
          }

          <TableBody>
            {filteredReferrals?.map((referral) => (
              <TableRow
                key={referral.id}
                sx={{
                  "& >td": {
                    p: "0.5rem",
                  },
                }}
              >
                <TableCell
                  sx={tableStyles.subTableCell}
                >{`${referral.firstName || "-"}`}</TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {showContactNumber(referral.mobile || "")}
                </TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {referral.city || "-"} {referral.state || ""}
                </TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {referral.urgencyOfReferral || "-"}
                </TableCell>

                <TableCell
                  sx={tableStyles.subTableCell}
                >{`${referral.referralDoctorFirstName || "-"}`}</TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {referral?.doctor?.user?.firstName || "-"}{" "}
                </TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {getFormattedDate(referral?.createdAt || "-")}
                </TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {getStatusIcon(referral?.status || "")}
                </TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {userRole === "DOCTOR" &&
                  selectedRefferalsFilter === "Pending" ? (
                    <Box display="flex" justifyContent="space-around">
                      <IconButton
                        onClick={() => handleEdit(referral)}
                        color="secondary"
                        disableRipple
                        sx={{
                          p: 0,
                          "&:hover": { backgroundColor: "transparent" },
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(referral.id)}
                        color="primary"
                        disableRipple
                        sx={{
                          p: 0,
                          "&:hover": { backgroundColor: "transparent" },
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ) : userRole === "ADMIN" &&
                    selectedRefferalsFilter === "Pending" ? (
                    <Box display="flex" gap={2.5}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="primary-button "
                        onClick={() => openCommentModal(referral, "Accepted")}
                        size="small"
                        sx={{ minHeight: "1.375rem", maxHeight: "1.375rem" }}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        className="warning-button"
                        onClick={() => openCommentModal(referral, "Rejected")}
                        size="small"
                        sx={{ minHeight: "1.375rem", maxHeight: "1.375rem" }}
                      >
                        Reject
                      </Button>
                    </Box>
                  ) : (
                    <>{referral?.comments || "-"}</>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <ContentCenter>
        <Typography className="no-data">
          {selectedRefferalsFilter === "Pending"
            ? "No Pending Referrals found"
            : selectedRefferalsFilter === "Accepted"
              ? "No Accepted Referrals found"
              : "No Rejected Referrals found"}
        </Typography>
      </ContentCenter>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        minHeight: "100%",
        // paddingInline: "20px",
        // mb: 2,
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <ComponentHeader title="Referral List" />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1.5rem",
          }}
        >
          {userRole === NETWORK && (
            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              startIcon={<AddIcon />}
              onClick={handleAddNew}
            >
              Add New
            </Button>
          )}
          {/* <DashboardFilter/> */}
        </Box>
      </Box>

      {isMobile ? (
        <Box
          sx={{
            marginBottom: "1rem",
            width: "max-content",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "var(--neuro-font-size-extra-small)",
              fontWeight: "var(--neuro-font-weight-semibold)",
            }}
          >
            Filter by:
          </Typography>

          <FilterByMobileDropdown
            open={open}
            selectedValue={selectedRefferalsFilter}
            setSelectedValue={setSelectedRefferalsFilter}
            handleClose={handleClose}
            handleOpen={handleOpen}
            options={options}
          />
        </Box>
      ) : (
        <Box
          sx={{
            marginBottom: "1rem",
            padding: "0.313rem 0.938rem",
            backgroundColor: "white",
            borderRadius: "1.875rem",
            width: "max-content",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            fontSize: "var(--neuro-font-size-extra-small)",
            height: "2.25rem",
            "& >.MuiFormGroup-root": {
              height: "2.25rem !important",
              maxHeight: "2.25rem !important",
            },
          }}
          className="outlined-secondary-button"
        >
          <Typography
            sx={{
              fontSize: "var(--neuro-font-size-extra-small)",
              fontWeight: "var(--neuro-font-weight-semibold)",
            }}
          >
            Filter by:
          </Typography>
          <FilterByAppointments
            selectedValue={selectedRefferalsFilter}
            setSelectedValue={setSelectedRefferalsFilter}
            options={options}
          />
        </Box>
      )}

      <Box sx={{ height: "calc(100% - 8.875rem)" }}>
        {loading ? (
          <ContentCenter>
            <LoadingScreen />
          </ContentCenter>
        ) : selectedRefferalsFilter === "All" ? (
          <AllRefferalsTable />
        ) : (
          <FilteredRefferalsTable />
        )}
      </Box>

      <AddEditReferralModal
        open={dialogOpen}
        onClose={handleCloseDialog}
        referral={editReferral}
      />

      <ConfirmDialog
        open={confirmOpen}
        title="Confirm Delete"
        message="Are you sure you want to delete this referral?"
        onClose={handleConfirmClose}
      />

      <CommentModal
        open={commentModalOpen}
        status={commentStatus}
        onClose={handleCommentModalClose}
      />
    </Box>
  );
};

export default ReferralsList;
