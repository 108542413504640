const RefferralsIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M50 37.5v10a2.5 2.5 0 0 1-2.5 2.5h-10a2.5 2.5 0 0 1-2.5-2.5v-10a2.5 2.5 0 0 1 2.5-2.5H40v-3.75A1.25 1.25 0 0 0 38.75 30h-27.5A1.25 1.25 0 0 0 10 31.25V35h2.5a2.5 2.5 0 0 1 2.5 2.5v10a2.5 2.5 0 0 1-2.5 2.5h-10A2.5 2.5 0 0 1 0 47.5v-10A2.5 2.5 0 0 1 2.5 35H5v-7.5A2.5 2.5 0 0 1 7.5 25h15v-7.5h-5A2.5 2.5 0 0 1 15 15V2.5A2.5 2.5 0 0 1 17.5 0h15A2.5 2.5 0 0 1 35 2.5V15a2.5 2.5 0 0 1-2.5 2.5h-5V25h15a2.5 2.5 0 0 1 2.5 2.5V35h2.5a2.5 2.5 0 0 1 2.5 2.5Z"
    />
  </svg>
);
export default RefferralsIcon;
