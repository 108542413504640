import React, { useState, useEffect } from "react";
import { fetchDoctors } from "../../services/doctorService";
import { Box } from "@mui/material";
import { Doctor } from "../../types/common";
import DoctorsList from "./DoctorsList";

const Doctors: React.FC = () => {
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  console.log("doctors", doctors);

  useEffect(() => {
    loadDoctors();
  }, []);

  const loadDoctors = async () => {
    try {
      const response = await fetchDoctors("DOCTORS");
      setDoctors(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching doctors:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          // minHeight: "100%",
          height: "100%",
          // paddingInline: "20px",
          paddingBlock: "1rem",
          paddingInline: "0.875rem",
        }}
      >
        <DoctorsList
          doctors={doctors}
          loading={loading}
          loadDoctors={loadDoctors}
        />
      </Box>
    </>
  );
};

export default Doctors;
