import { useRef, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Box,
  Popper,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useAppStore from "../../store/appStore";
import Button from "../ui/Button";
import { getStartAndEndDateTimesForRange } from "../../utils/common";

export default function DashboardFilter() {
  // props & state values
  const {
    range,
    setRange,
    setCustomEndDate,
    setCustomStartDate,
    showSnackbar,
  } = useAppStore();
  const [open, setOpen] = useState(false);

  const today = new Date().toISOString().split("T")[0];

  const [popperOpen, setPopperOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const anchorRef = useRef<null | HTMLElement>(null);

  console.log("startDate, endDate", startDate, endDate);

  const menuItems = [
    {
      value: "TODAY",
      label: "Today",
    },
    {
      value: "YESTERDAY",
      label: "Yesterday",
    },
    {
      value: "THIS_WEEK",
      label: "This Week",
    },
    {
      value: "LAST_WEEK",
      label: "Last Week",
    },
    {
      value: "THIS_MONTH",
      label: "This Month",
    },
    { value: "LAST_MONTH", label: "Last Month" },
    { value: "THIS_YEAR", label: "This Year" },
    { value: "LAST_YEAR", label: "Last Year" },
    { value: "CUSTOM", label: "Custom Range" },
  ];

  // callbacks & functions
  // const handleChange = (event: SelectChangeEvent<typeof range>) => {
  //   setRange(event.target.value);
  //   if (event.target.value === "CUSTOM") {
  //     setCustomStartDate("");
  //     setCustomEndDate("");
  //   }
  // };

  function convertToUTC(dateStr: string): string {
    const localDate = new Date(dateStr);
    return localDate.toISOString();
  }

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setRange(selectedValue);

    if (selectedValue !== "CUSTOM") {
      const { start, end } = getStartAndEndDateTimesForRange(selectedValue);
      setCustomStartDate(start);
      setCustomEndDate(end);
    }

    if (selectedValue === "CUSTOM") {
      setPopperOpen(true);
    } else {
      setPopperOpen(false);
    }
  };

  const handleApply = (event: any) => {
    event.preventDefault();

    if (startDate === "") {
      showSnackbar("Start date is required", "error");
      return;
    }
    if (endDate === "") {
      showSnackbar("End date is required", "error");
      return;
    }
    setCustomStartDate(convertToUTC(startDate));
    setCustomEndDate(convertToUTC(endDate));
    setPopperOpen(false);
  };

  // const handlePopoverClose = () => {
  //   setPopperOpen(false);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  return (
    <>
      <FormControl sx={{ minWidth: "8.75rem", minHeight: "1.25rem" }}>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          ref={anchorRef}
          // onClose={handleClose}
          // onOpen={handleOpen}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          value={range}
          onChange={handleChange}
          IconComponent={KeyboardArrowDownIcon}
          sx={{
            backgroundColor: "var(--neuro-white-text)", // White background
            borderRadius: "0.625rem", // Rounded corners
            boxShadow: "0 0 0.5rem rgba(0, 0, 0, 0.1)", // Remove default shadow
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent", // Remove the default border
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--neuro-secondary_border)", // Light border on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--neuro-secondary_border)", // Light border when focused
            },
            ".MuiSelect-select": {
              padding: "0.5rem 1rem", // Padding for the selected value
              display: "flex",
              alignItems: "center",
            },
            ".MuiSvgIcon-root": {
              color: "var(--neuro-black-text)",
            },
          }}
        >
          {menuItems?.map((menu, i) => (
            <MenuItem
              key={i}
              value={menu?.value}
              sx={{
                backgroundColor:
                  range === menu?.value
                    ? "var(--neuro-button-bg-success) !important"
                    : "inherit",
                color:
                  range === menu?.value
                    ? "var(--neuro-white-text) !important"
                    : "inherit",
              }}
            >
              {menu?.label}
              {/* {customStartDate &&
              customStartDate !== "" &&
              customEndDate &&
              customEndDate !== ""
                ? `${customStartDate} - ${customEndDate}`
                : null} */}
            </MenuItem>
          ))}
        </Select>

        <Popper
          // id="demo-controlled-open-select"
          open={popperOpen}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          // onClick={handlePopoverClose}
        >
          <Box
            sx={{
              p: "1rem",
              backgroundColor: "var(--neuro-white-text)",
              borderRadius: "0.5rem",
              border: "1px solid var(--neuro-secondary_border)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.875rem",
              }}
              component="form"
            >
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputProps={{ inputProps: { max: today } }}
                InputLabelProps={{ shrink: true }}
                required
              />
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputProps={{ inputProps: { max: today } }}
                InputLabelProps={{ shrink: true }}
                required
              />
              <Button
                type="submit"
                variant="contained"
                className="primary-button"
                onClick={handleApply}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Popper>
      </FormControl>
    </>
  );
}
