import React, { useCallback, useEffect, useMemo, useState } from "react";

// third-party imports
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";

// project imports
import AppointmentDetails from "../AppointmentConfirmation/AppointmentDetails";
import useAppointmentStore from "../../../store/appointmentsStore";
import { useNavigate } from "react-router-dom";
import {
  getAppointmentStatus,
  getFormattedDateTime,
  getStatusColor,
  specializationMapping,
} from "../../../utils/appointments";
import useAuthStore from "../../../store/authStore";
import { isAdmin, isDoctor, isPatient } from "../../../utils/auth";
import AppointmentCardButtons from "./AppointmentCardButtons";
import { capitalizeFirstLetter } from "../../../utils/common";
import {
  CANCELLED,
  COMPLETED,
  ONGOING,
  ONLINE,
  UPCOMING,
} from "../../../utils/constants";
import { AppointmentCardProps } from "../../../types/appointments";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PatientName from "./PatientName";
import DoctorName from "./DoctorName";
import useAppStore from "../../../store/appStore";

const AppointmentCard: React.FC<AppointmentCardProps> = ({
  appointmentCode,
  appointmentDate,
  id,
  availableSlot,
  doctor,
  status,
  patient,
  paymentStatus,
  appointmentMode,
  sortedAppointments,
  patientId,
  isTranscriptionsAvailable,
  prescription,
  from = "",
  usedIn = "",
}) => {
  // props & state values
  const navigate = useNavigate();

  const styles = {
    detailsIconStyles: {
      fontSize: "var(--neuro-font-size-medium)",
      color: "var(--neuro-black-text)",
    },
    detailsIconButtonStyles: {
      width: "2.25rem",
      height: "2.25rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
    },
  };

  const isMobile = useMediaQuery("(max-width:37.5rem)");

  const [appointmentDetails, setAppointmentDetails] = useState<any>(null);
  const { setAppointmentConferenceView, setAppointmentMeetId } = useAppStore();

  const { user, userRole } = useAuthStore();

  // const isDoctorOrAdmin = isDoctor(userRole) || isAdmin(userRole);

  // const doctorName = `Dr. ${capitalizeFirstLetter(doctor?.user?.firstName || "")} ${capitalizeFirstLetter(doctor?.user?.lastName || "")}`;

  const doctorName = `Dr. ${capitalizeFirstLetter(doctor?.user?.firstName || "")}`;

  // const appointementPatientName = `${capitalizeFirstLetter(patient?.user?.firstName || "")} ${capitalizeFirstLetter(patient?.user?.lastName || "")}`;

  const appointementPatientName = `${capitalizeFirstLetter(patient?.user?.firstName || "")}`;

  const {
    cancelAppointmentInfo,
    fetchAppointments,
    selectedAppointmentId,
    setSelectedAppointmentId,
  } = useAppointmentStore();

  const shouldRenderDetails =
    selectedAppointmentId === id &&
    appointmentDetails &&
    appointmentDetails?.id === selectedAppointmentId;

  // callbacks & functions
  useEffect(() => {
    if (cancelAppointmentInfo) {
      if (patientId) {
        if (from === "dashboard") {
          fetchAppointments(patientId, "DASHBOARD");
        } else {
          fetchAppointments(patientId);
        }
      } else {
        if (from === "dashboard") {
          fetchAppointments("", "DASHBOARD");
        } else {
          fetchAppointments();
        }
      }
    }
    return () => {
      setSelectedAppointmentId(null);
    };
  }, [cancelAppointmentInfo]);

  // Handle icon button click
  const handleAppointmentClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (selectedAppointmentId === id) {
        setSelectedAppointmentId(null);
        setAppointmentDetails(null);
      } else {
        setSelectedAppointmentId(id);
        const details = sortedAppointments?.find((appt) => appt.id === id);
        setAppointmentDetails(details || null);
      }
    },
    [selectedAppointmentId, id, sortedAppointments, setSelectedAppointmentId]
  );

  const appointmentStatus = useMemo(
    () =>
      getAppointmentStatus(availableSlot?.startTime, availableSlot?.endTime),
    [availableSlot]
  );

  const isCompletedOrCancelled = useMemo(
    () =>
      [CANCELLED, COMPLETED].includes(status) ||
      appointmentStatus === COMPLETED,
    [status, appointmentStatus]
  );

  const cardStyles = useMemo(() => {
    const background = isCompletedOrCancelled
      ? "#f4f4f4"
      : "var(--neuro-white-text)";
    const borderLeft = isCompletedOrCancelled
      ? "1px solid var(--neuro-secondary_border)"
      : appointmentStatus === UPCOMING
        ? "4px solid var(--neuro-button-bg-primary)"
        : appointmentStatus === ONGOING
          ? "4px solid var(--neuro-button-bg-success)"
          : "1px solid var(--neuro-secondary_border)";

    return {
      background,
      borderLeft,
      opacity: isCompletedOrCancelled ? 0.9 : 1,
    };
  }, [status, appointmentStatus]);

  // Handle card click
  const handleCardClick = useCallback(() => {
    const path = patientId
      ? isAdmin(userRole)
        ? `/patients/${patientId}/appointments/${id}/details`
        : `/appointments/patient/${patientId}/appointments/${id}/details`
      : `/appointment/${id}`;
    if (from === "meet") {
      setAppointmentMeetId(id);
      setAppointmentConferenceView("Details");
    } else {
      navigate(path);
    }
  }, [navigate, id, patientId]);

  const doctorSpecialisations = useCallback(() => {
    const { specializations } = doctor || {};
    if (!specializations?.length) return "";
    const mappedSpecializations = specializations?.map(
      (specialization: string) =>
        specializationMapping[specialization] || specialization
    );
    return isMobile
      ? specializations.length > 1
        ? `${mappedSpecializations[0]}...`
        : mappedSpecializations[0]
      : mappedSpecializations.join(", ");
  }, [doctor, isMobile]);

  const formattedDate = useMemo(() => {
    if (!availableSlot?.startTime) return null;
    const DateTimeObject = getFormattedDateTime(availableSlot.startTime);

    if (DateTimeObject && typeof DateTimeObject === "object") {
      const { day, month, year, hours, minutes, amOrPm } = DateTimeObject;
      return {
        date: `${day} ${month} ${year}`,
        time: `${hours}:${minutes} ${amOrPm}`,
      };
    } else return "";
  }, [availableSlot]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginBottom: isMobile ? "1rem" : "0.625rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          gap: "1rem",
          alignItems: "center",
          background: cardStyles.background,
          border: "1px solid var(--neuro-secondary_border)",
          borderLeft: cardStyles.borderLeft,
          borderRadius:
            selectedAppointmentId === id &&
            appointmentDetails &&
            appointmentDetails.id === selectedAppointmentId
              ? "0.75rem 0.75rem 0 0"
              : "0.75rem",
          padding: isMobile
            ? "0.75rem 0.875rem"
            : "0.625rem 1.625rem 0.5rem 1.75rem",
          cursor: "pointer",
          flexWrap: "wrap",
        }}
        onClick={handleCardClick}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: isMobile ? "space-between" : "normal",
            alignItems: isMobile ? "center" : "normal",
            width: isMobile ? "100%" : "initial",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              width: "7.5rem",
            }}
          >
            <Typography
              sx={{
                paddingBlock: "0.125rem",
                borderRadius: "0.25rem",
                backgroundColor:
                  status === CANCELLED
                    ? "var(--neuro-grey-variant1)"
                    : status === COMPLETED
                      ? "var(--neuro-grey-variant1)"
                      : getStatusColor(appointmentStatus),
                color: "var(--neuro-white-text)",
                opacity: cardStyles.opacity,
                fontSize: "var(--neuro-font-size-x-small)",
                fontFamily: "var(--neuro-font-family-roboto-slab)",
                lineHeight: "120%",
                height: "1.25rem",
                width: "7.5rem",
                textAlign: "center",
              }}
            >
              {status === CANCELLED
                ? CANCELLED
                : status === COMPLETED
                  ? COMPLETED
                  : appointmentStatus || ""}
            </Typography>

            <Typography
              sx={{
                fontSize: "var(--neuro-font-size-x-small)",
                marginTop: "0.625rem",
                fontFamily: "var(--neuro-font-family-roboto-slab)",
                lineHeight: "120%",
                color: "var(--neuro-darkgrey_border)",
                width: "max-content",
              }}
            >
              {formattedDate ? formattedDate?.date || "" : ""}
            </Typography>

            <Typography
              sx={{
                marginTop: "0.125rem",
                fontSize: "var(--neuro-font-size-x-small)",
                fontFamily: "var(--neuro-font-family-roboto-slab)",
                lineHeight: "120%",
                color: "var(--neuro-darkgrey_border)",
                textAlign: "center",
              }}
            >
              {formattedDate ? formattedDate?.time || "" : ""}
            </Typography>
          </Box>

          {!isMobile && (
            <Box
              sx={{
                backgroundColor: "var(--neuro-secondary_border)",
                width: "0.063rem",
                // height: "5.094rem",
                marginTop: "-0.625rem",
                marginBottom: "-0.5rem",
                marginRight: "3.813rem",
                marginLeft: "3.188rem",
                "@media (max-width: 87.5rem)": {
                  marginLeft: "0.625rem",
                  marginRight: "0.625rem",
                },
              }}
            />
          )}

          <Box>
            <Typography
              sx={{
                fontSize: "var(--neuro-font-size-x-small)",
                color: "var(--neuro-black-text)",
                fontFamily: "var(--neuro-font-family-roboto-slab)",
                lineHeight: "120%",
              }}
            >
              {appointmentMode
                ? appointmentMode === ONLINE
                  ? "Online Consultation"
                  : "Clinic Visit"
                : ""}{" "}
            </Typography>

            {isPatient(userRole) ||
            from === "meet" ||
            (isAdmin(userRole) && patientId) ? (
              <DoctorName name={doctorName} id={doctor?.id || ""} from={from} />
            ) : isDoctor(userRole) || (!patientId && isAdmin(userRole)) ? (
              <PatientName
                name={appointementPatientName}
                id={patient?.id || ""}
                patient={patient}
              />
            ) : (
              <Typography
                sx={{
                  marginTop: "0.75rem",
                  fontWeight: "var(--neuro-font-weight-bold)",
                  color: "var(--neuro-bg-darkblue-primary)",
                  fontFamily: "var(--neuro-font-family-roboto)",
                  lineHeight: "var(--neuro-line-height-tiny-plus)",
                }}
              >
                {" "}
              </Typography>
            )}

            {isAdmin(userRole) && !patientId && (
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                  marginTop: "0.25rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "var(--neuro-font-weight-bold)",
                    color: "var(--neuro-black-text)",
                    fontFamily: "var(--neuro-font-family-roboto)",
                    lineHeight: "var(--neuro-line-height-tiny-plus)",
                  }}
                >
                  With
                </Typography>
                <DoctorName
                  name={doctorName}
                  id={doctor?.id || ""}
                  from={from}
                  noMarginTop
                />
              </Box>
            )}

            <Typography
              sx={{
                fontSize: "var(--neuro-font-size-x-small)",
                color: "var(--neuro-darkgrey_border)",
                fontFamily: "var(--neuro-font-family-roboto-slab)",
                lineHeight: "var(--neuro-line-height-tiniest-plus)",
                marginTop: "0.063rem",
              }}
            >
              {isPatient(userRole) || (isAdmin(userRole) && patientId)
                ? doctorSpecialisations()
                : ""}
            </Typography>
          </Box>

          {isMobile && (
            <IconButton
              onClick={(event) => handleAppointmentClick(event)}
              sx={styles.detailsIconButtonStyles}
            >
              {shouldRenderDetails ? (
                <KeyboardArrowDownIcon sx={styles.detailsIconStyles} />
              ) : (
                <KeyboardArrowRightIcon sx={styles.detailsIconStyles} />
              )}
            </IconButton>
          )}
        </Box>

        {isMobile && (
          <Box
            sx={{
              backgroundColor: "var(--neuro-secondary_border)",
              width: "100%",
              height: "0.063rem",
              marginBlock: "0.625rem",
            }}
          />
        )}

        {/* Card Buttons */}
        <Box
          sx={{
            width: isMobile ? "100%" : "auto",
            height: isMobile ? "1.5rem" : "2.875rem",
          }}
        >
          <AppointmentCardButtons
            appointmentCode={appointmentCode}
            appointmentDate={appointmentDate}
            id={id}
            userRole={userRole}
            appointmentMode={appointmentMode}
            status={status}
            availableSlot={availableSlot}
            paymentStatus={paymentStatus}
            doctor={doctor}
            patient={patient}
            handleAppointmentClick={handleAppointmentClick}
            user={user}
            patientId={patientId}
            shouldRenderDetails={Boolean(shouldRenderDetails)}
            prescription={prescription}
            from={from}
          />
        </Box>
      </Box>

      {shouldRenderDetails && (
        <>
          <Box
            sx={{
              backgroundColor: "var(--neuro-white-text)",
              border: "1px solid var(--neuro-secondary_border)",
              borderRadius: "0 0 0.75rem 0.75rem",
              padding: "2.563rem 1.625rem 2.563rem 1.75rem",
            }}
          >
            <AppointmentDetails
              from="list"
              appointmentDetails={appointmentDetails}
              patientId={patientId}
              usedIn={usedIn}
              isTranscriptionsAvailable={isTranscriptionsAvailable}
              isFromMeet={from === "meet" ? true : false}
              isFromDashboard={from === "dashboard" ? true : false}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default AppointmentCard;
