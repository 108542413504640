import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, useMediaQuery } from "@mui/material";

import DocumentList from "./DocumentList";
import OriginalReport from "./OriginalReport";
import ReportSummary from "./ReportSummary";
import useAuthStore from "../../../store/authStore";
import useDocumentStore from "../../../store/documentsStore";
import useAppStore from "../../../store/appStore";
import { DocumentsProps } from "../../../types/documents";
import { PATIENT } from "../../../utils/constants";

const ReportViewer: React.FC<DocumentsProps> = ({ patientId, from = "" }) => {
  const navigate = useNavigate();
  const { userRole } = useAuthStore();
  const isMobile = useMediaQuery("(max-width:50rem)");

  const { isMenuCollapsed, setIsMenuCollapsed, setDocumentsConferenceView } =
    useAppStore();

  const [previousSidebarCollpased, _] = useState<boolean>(
    isMenuCollapsed ? true : false
  );

  const {
    documents,
    reGeneratedSummaryData,
    cleanUpReGenerateSummaryState,
    documentDetails,
    loadingDocumentDetails,
    errorDocumentDetails,
    fetchDocumentDetails,
    cleanUpDocumentDetailsStore,
    selectedDocumentId,
    setSelectedDocumentId,
  } = useDocumentStore();

  console.log("documentDetails", documentDetails);

  useEffect(() => {
    setIsMenuCollapsed(true);

    return () => {
      cleanUpDocumentDetailsStore();
      setIsMenuCollapsed(previousSidebarCollpased);
    };
  }, []);

  useEffect(() => {
    if (selectedDocumentId) {
      fetchDocumentDetails(selectedDocumentId);
      cleanUpReGenerateSummaryState();
    }
  }, [selectedDocumentId, reGeneratedSummaryData]);

  useEffect(() => {
    if (documents && documents.length > 0 && selectedDocumentId) {
      const document = documents.filter((doc) => doc.id === selectedDocumentId);
      if (document.length === 0) {
        setSelectedDocumentId(documents[0]?.id);
      }
    }
  }, [documents, selectedDocumentId]);

  // redirect to documents page if no documents
  useEffect(() => {
    if (documents && documents.length === 0) {
      if (patientId) {
        if (from === "meet") {
          setDocumentsConferenceView("List");
        } else {
          navigate(`/patients/${patientId}/documents`);
        }
      } else {
        navigate("/documents");
      }
    }
  }, [documents]);

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-white-text)",
        height: "100%",
        padding: "1.25rem",
        paddingBottom: 0,
      }}
    >
      <Grid
        container
        spacing={2}
        flexWrap="wrap"
        sx={{ height: "100%", overflow: "auto" }}
      >
        {userRole === PATIENT && (
          <>
            <Grid
              item
              xs={12}
              md={
                isMenuCollapsed
                  ? from === "meet"
                    ? 12
                    : 3.5
                  : from === "meet"
                    ? 12
                    : 4
              }
              xl={from === "meet" ? 12 : 3}
              sx={{
                height: isMobile ? "auto" : "100%",
                overflowY: "hidden",
              }}
            >
              <DocumentList patientId={patientId} />
            </Grid>
            <Grid
              item
              xs={12}
              md={
                isMenuCollapsed
                  ? from === "meet"
                    ? 12
                    : 8.5
                  : from === "meet"
                    ? 12
                    : 8
              }
              xl={from === "meet" ? 12 : 9}
              sx={{
                height: "100%",
                overflowY: "hidden",
              }}
            >
              <OriginalReport
                key={documentDetails?.id}
                loading={loadingDocumentDetails}
                error={Boolean(errorDocumentDetails)}
                fileType={documentDetails?.fileType}
                filePath={documentDetails?.filePath}
                fileName={documentDetails?.name}
                id={documentDetails?.id}
                status={documentDetails?.status}
                patientId={patientId}
              />
            </Grid>
          </>
        )}

        {userRole !== PATIENT && (
          <>
            <Grid
              item
              xs={12}
              md={
                isMenuCollapsed
                  ? from === "meet"
                    ? 12
                    : 2.5
                  : from === "meet"
                    ? 12
                    : 3
              }
              xl={from === "meet" ? 12 : 3}
              sx={{
                height: "100%",
                overflowY: "hidden",
              }}
            >
              <DocumentList patientId={patientId} from={from} />
            </Grid>

            <Grid
              item
              xs={12}
              md={from === "meet" ? 12 : 5}
              xl={from === "meet" ? 12 : 5}
              sx={{
                height: "100%",
                overflowY: "hidden",
              }}
            >
              <OriginalReport
                loading={loadingDocumentDetails && !documentDetails}
                error={Boolean(errorDocumentDetails) && !documentDetails}
                fileType={documentDetails?.fileType}
                filePath={documentDetails?.filePath}
                fileName={documentDetails?.name}
                id={documentDetails?.id}
                status={documentDetails?.status}
                patientId={patientId}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={
                isMenuCollapsed
                  ? from === "meet"
                    ? 12
                    : 4.5
                  : from === "meet"
                    ? 12
                    : 4
              }
              xl={from === "meet" ? 12 : 4}
              sx={{ height: "100%", overflowY: "hidden" }}
            >
              <ReportSummary
                loading={loadingDocumentDetails}
                id={documentDetails?.id}
                metadata={documentDetails?.metadata}
                doctorSummary={documentDetails?.doctorSummary}
                patientSummary={documentDetails?.patientSummary}
                fileName={documentDetails?.name}
                fileType={documentDetails?.fileType}
                isDoctorApproved={documentDetails?.isDoctorApproved}
                feedback={documentDetails?.feedback}
                transcriptions={documentDetails?.transcriptions}
                patientNote={documentDetails?.patientNote}
                symptomAssessment={documentDetails?.symptomAssessment}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ReportViewer;
