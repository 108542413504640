import React, { useState } from "react";
import { Grid } from "@mui/material";
import ViewerReportCard from "../view/ViewerReportCard";
import useDocumentStore from "../../../store/documentsStore";
import { DocumentGridProps, Document } from "../../../types/documents";
import SortByDocuments from "./SortByDocuments";

const DocumentGrid: React.FC<DocumentGridProps> = ({
  documents,
  isMenuCollapsed,
  patientId,
  isFromMeet = false,
}) => {
  const { setSelectedDocumentId } = useDocumentStore();
  console.log("documents", documents);
  const [selectedOption, setSelectedOption] = useState<string>("Uploaded Date");

  const sortOptions = ["Uploaded Date", "Report Type", "Report Date"];

  const sortDocuments = (docs: Document[]) => {
    switch (selectedOption) {
      case "Uploaded Date":
        return [...docs].sort(
          (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
        );
      case "Report Date":
        return [...docs].sort((a, b) => {
          if (!a.reportDate) return -1;
          if (!b.reportDate) return 1;
          return Date.parse(b.reportDate) - Date.parse(a.reportDate);
        });
      case "Report Type":
        const typeOrder: Record<string, number> = {
          AUDIO: 1,
          DICOM: 2,
          IMAGE: 3,
          PDF: 4,
          ZIP: 5,
        };
        return [...docs].sort(
          (a, b) =>
            typeOrder[a.fileType.toUpperCase()] -
            typeOrder[b.fileType.toUpperCase()]
        );
      default:
        return docs;
    }
  };

  const sortedDocuments = sortDocuments(documents);

  return (
    <Grid container spacing={2.5}>
      <Grid item xs={12} sx={{ mb: "-0.25rem" }}>
        <SortByDocuments
          options={sortOptions}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </Grid>

      {sortedDocuments.map((document) => (
        <Grid
          item
          xs={12}
          md={isMenuCollapsed ? (isFromMeet ? 6 : 3) : isFromMeet ? 6 : 4}
          xl={isFromMeet ? 6 : 3}
          key={document.id}
        >
          <ViewerReportCard
            {...document}
            from="list"
            setSelectedDocumentId={setSelectedDocumentId}
            patientId={patientId}
            isFromMeet={isFromMeet}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DocumentGrid;
