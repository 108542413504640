import React from "react";
import { Typography } from "@mui/material";
import LoadingScreen from "../../shared/LoadingScreen";
import DocumentGrid from "./DocumentGrid";
import ContentCenter from "../../shared/ContentCenter";
import { DocumentsSectionProps } from "../../../types/documents";

const DocumentsSection: React.FC<DocumentsSectionProps> = ({
  isMenuCollapsed,
  loading,
  error,
  documents,
  patientId,
  from = "",
}) => (
  <>
    {loading && <LoadingScreen />}

    {error && (
      <ContentCenter>
        <Typography className="no-data">Documents not found</Typography>
      </ContentCenter>
    )}

    {!loading && !error && documents && documents.length === 0 && (
      <ContentCenter>
        <Typography className="no-data">Documents not found</Typography>
      </ContentCenter>
    )}

    {!loading && !error && documents && documents.length > 0 && (
      <DocumentGrid
        documents={documents}
        isMenuCollapsed={isMenuCollapsed}
        from="list"
        isFromMeet={from === "meet" ? true : false}
        patientId={patientId}
      />
    )}
  </>
);

export default DocumentsSection;
