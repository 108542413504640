import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import ViewerReportCard from "./ViewerReportCard";
import LoadingScreen from "../../shared/LoadingScreen";
import ContentCenter from "../../shared/ContentCenter";
import useAppStore from "../../../store/appStore";
import useDocumentStore from "../../../store/documentsStore";
import { DocumentsProps } from "../../../types/documents";

const DocumentList: React.FC<DocumentsProps> = ({ patientId, from = "" }) => {
  const { showSnackbar } = useAppStore();

  const {
    documentDeleted,
    loadingDeleteDocument,
    errorDeleteDocument,
    cleanUpDeleteDocumentState,
    documents,
    loadingDocuments,
    errorDocuments,
    fetchDocuments,
    setSelectedDocumentId,
    selectedDocumentId,
  } = useDocumentStore();

  const listRef = useRef<HTMLDivElement | null>(null);
  const selectedCardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (loadingDeleteDocument) {
      showSnackbar("Deleting document, please wait", "info");
    } else if (errorDeleteDocument) {
      showSnackbar("Could not delete document, please try again", "error");
      cleanUpDeleteDocumentState();
    } else if (documentDeleted) {
      showSnackbar("Document deleted", "success");
      if (patientId) {
        fetchDocuments(patientId, "DOCUMENT_DETAILS");
      } else {
        fetchDocuments("", "DOCUMENT_DETAILS");
      }
      cleanUpDeleteDocumentState();
    }
  }, [loadingDeleteDocument, errorDeleteDocument, documentDeleted]);

  useEffect(() => {
    if (selectedCardRef.current && listRef.current) {
      listRef.current.scrollTop = selectedCardRef.current.offsetTop - 250;
    }
  }, []);

  // Extracted styles to a variable
  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    // paddingRight: "1.25rem",
    borderRight: "1px solid var(--neuro-secondary_border)",
    height: "100%",
    // overflowY: "auto",
  };

  const titleStyles = {
    fontSize: "var(--neuro-font-size-tiny)",
    lineHeight: "var(--neuro-line-height-micro)",
    color: "var(--neuro-black-text)",
    fontFamily: "var(--neuro-font-family-roboto-condensed)",
  };

  const listStyles = {
    display: "flex",
    flexDirection: "column",
    gap: "0.875rem",
    height: "calc(100% - 6.25rem)",
    overflow: "auto",
    // paddingRight: "1rem",
    paddingRight: "1.25rem",
  };

  return (
    <Box sx={containerStyles}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "1.188rem",
          borderBottom: "0.063rem dashed var(--neuro-secondary_border)",
          minHeight: "3.75rem",
          marginRight: "1.25rem",
        }}
      >
        <Typography sx={titleStyles}>Uploaded Documents</Typography>
      </Box>

      <Box sx={listStyles} ref={listRef}>
        <>
          {loadingDocuments && <LoadingScreen />}

          {errorDocuments && (
            <ContentCenter>
              <Typography className="no-data">Documents not found</Typography>
            </ContentCenter>
          )}

          {!loadingDocuments &&
            !errorDocuments &&
            documents &&
            documents.length === 0 && (
              <ContentCenter>
                <Typography className="no-data">Documents not found</Typography>
              </ContentCenter>
            )}

          {!loadingDocuments &&
            !errorDocuments &&
            documents &&
            documents.length > 0 &&
            documents.map((document) => (
              <div
                key={document?.id}
                ref={
                  document.id === selectedDocumentId ? selectedCardRef : null
                }
              >
                <ViewerReportCard
                  key={document?.id}
                  {...document}
                  setSelectedDocumentId={setSelectedDocumentId}
                  patientId={patientId}
                  isFromMeet={from === "meet" ? true : false}
                />
              </div>
            ))}
        </>
      </Box>
    </Box>
  );
};

export default DocumentList;
