import { useEffect, useState } from "react";
import { Box, Typography, Avatar, Grid, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { getDoctorProfileApi } from "../../services/profileService";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import { capitalizeFirstLetter } from "../../utils/common";
import { specializationMapping } from "../../utils/appointments";
import HorizontalTabs from "../profile/patient/HorizontalTabs";

function DoctorDetails({
  doctorId,
  from = "",
}: {
  doctorId?: string;
  from?: string;
}) {
  // props & stat values
  const isMobile = useMediaQuery("(max-width:37.5rem)");
  const { id } = useParams();

  const styles = {
    textStyles: {
      fontFamily: "var(--neuro-font-family-roboto)",
      lineHeight: "120%",
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  };

  const [details, setDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedTab, setSelectedTab] = useState<string>("General Details");

  const tabs = [
    "General Details",
    //  "Availability", "Certifications and Awards"
  ];

  // callbacks & functions
  const getProfileDetails = async (id: string) => {
    try {
      const response = await getDoctorProfileApi(id);
      setDetails(response.data || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id || doctorId) {
      getProfileDetails(id || doctorId || "");
    }
  }, [id, doctorId]);

  const onTabClick = (tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <Box
      sx={{
        padding: from === "profile" ? 0 : "1.813rem 1.375rem",
        background: "var(--neuro-bg-light-grey-secondary)",
      }}
    >
      {from !== "profile" && (
        <Typography
          sx={{
            fontSize: "var(--neuro-font-size-large-small)",
            fontFamily: "var(--neuro-font-family-roboto-condensed)",
          }}
        >
          View Details
        </Typography>
      )}

      {from !== "profile" && (
        <HorizontalTabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          onClick={onTabClick}
        />
      )}

      <Box sx={{ mt: from !== "profile" ? "1rem" : 0 }}>
        <>
          {selectedTab === "General Details" && (
            <>
              {loading && <LoadingScreen />}

              {!loading && !details && (
                <ContentCenter>
                  <Typography className="no-data">No details found</Typography>
                </ContentCenter>
              )}

              {!loading && details && (
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: "var(--neuro-white-text)",
                    borderRadius: "0.313rem",
                    // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                    padding: "1.813rem 1.375rem",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: isMobile ? 0 : "5.938rem",
                        alignItems: "center",
                        marginBottom: isMobile ? "1.5rem" : 0,
                      }}
                    >
                      <Avatar
                        sx={{
                          width: "11.875rem",
                          height: "11.875rem",
                          border: "2px solid var(--neuro-button-bg-success)",
                          backgroundColor: "var(--neuro-secondary_border)",
                        }}
                        src={details?.user?.profileImageUrl || undefined}
                      />
                    </Box>

                    <Grid container spacing={3}>
                      <Grid item container spacing={3.5} xs={12} sm={6}>
                        <Grid item xs={12}>
                          <Typography sx={styles.textStyles}>
                            <span className="bold-text">Doctor Name:</span>{" "}
                            {capitalizeFirstLetter(
                              details?.user?.firstName || ""
                            )}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography sx={styles.textStyles}>
                            <span className="bold-text">Email:</span>{" "}
                            {details?.user?.email || ""}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography sx={styles.textStyles}>
                            <span className="bold-text">Clinc:</span>{" "}
                            {details?.defaultHospital?.name || ""}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography sx={styles.textStyles}>
                            <span className="bold-text">Qualifications:</span>{" "}
                            {details?.qualifications || ""}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography sx={styles.textStyles}>
                            <span className="bold-text">Spoken Languages:</span>{" "}
                            {details?.spokenLanguages &&
                            details?.spokenLanguages.length > 0
                              ? details?.spokenLanguages.map(
                                  (language: string, index: number) => (
                                    <span key={index}>
                                      {language}
                                      {index !==
                                      details?.spokenLanguages.length - 1
                                        ? ","
                                        : null}
                                    </span>
                                  )
                                )
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item container spacing={3.5} xs={12} sm={6}>
                        <Grid item xs={12}>
                          <Typography sx={styles.textStyles}>
                            <span className="bold-text">Mobile No:</span>{" "}
                            {details?.user?.mobile || ""}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography sx={styles.textStyles}>
                            <span className="bold-text">Specialization:</span>{" "}
                            {details?.specializations &&
                            details?.specializations.length > 0
                              ? details?.specializations
                                  .map(
                                    (specialization: string) =>
                                      specializationMapping[specialization] ||
                                      specialization
                                  )
                                  .join(", ")
                              : ""}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography sx={styles.textStyles}>
                            <span className="bold-text">
                              Years of Experience:
                            </span>{" "}
                            {details?.yearsOfExperience || ""}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography sx={styles.textStyles}>
                            <span className="bold-text">
                              Online Consultation Fee:
                            </span>{" "}
                            {details?.onlineConsultationFee || ""}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography sx={styles.textStyles}>
                            <span className="bold-text">
                              Inperson Consultation Fee:
                            </span>{" "}
                            {details?.inPersonConsultationFee || ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>

                  {details?.about && (
                    <Box sx={{ mt: "2rem" }}>
                      <Typography sx={styles.textStyles}>
                        About Dr.{" "}
                        {capitalizeFirstLetter(details?.user?.firstName || "")}{" "}
                        {/* {capitalizeFirstLetter(details?.user?.lastName || "")}{" "} */}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mt: "0.5rem" }}
                      >
                        {details?.about || ""}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </>
          )}
        </>
      </Box>
    </Box>
  );
}

export default DoctorDetails;
