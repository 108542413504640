import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { PatientsListProps } from "../../types/patients";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/common";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import { tableStyles } from "../../utils/styles";

const PatientsList: React.FC<PatientsListProps> = ({
  patients,
  selectedPatient,
  setSelectedPatient,
}) => {
  // props & state values
  const navigate = useNavigate();

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          border: "0.063rem solid var(--neuro-secondary_border)",
          borderBottom: "none",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow
              sx={{
                "& >th": {
                  p: "0.5rem",
                },
              }}
            >
              <TableCell sx={tableStyles.tableCell}>Name</TableCell>

              <TableCell sx={tableStyles.tableCell}>MRN/Ref No</TableCell>

              <TableCell sx={tableStyles.tableCell}>Mobile</TableCell>

              <TableCell sx={tableStyles.tableCell}>Email</TableCell>

              <TableCell sx={tableStyles.tableCell}>Gender</TableCell>

              <TableCell sx={tableStyles.tableCell}>Registered On</TableCell>

              <TableCell sx={tableStyles.tableCell}>
                Initial Appointment
              </TableCell>

              <TableCell sx={tableStyles.tableCell}>
                Reasons for Appointment
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {patients?.map((patient) => (
              <TableRow
                key={patient.id}
                sx={{
                  backgroundColor:
                    selectedPatient && selectedPatient?.id === patient?.id
                      ? "var(--neuro-bg-light-grey-secondary)"
                      : "var(--neuro-white-text)",
                  cursor: "pointer",
                  "& >td": {
                    p: "0.5rem",
                  },
                }}
                onClick={() => {
                  setSelectedPatient(patient || null);
                  navigate(`/patients/${patient?.id}/profile`);
                }}
              >
                <TableCell
                  sx={{
                    fontWeight: 500,
                    color: "var(--neuro-table-head-primary)",
                    fontFamily: "var(--neuro-font-family-roboto-condensed)",
                    fontSize: "14px",
                  }}
                >
                  {capitalizeFirstLetter(patient.user?.firstName || "") || "-"}
                </TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {patient.mrn ? patient.mrn : patient?.referenceNumber || "-"}
                </TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {patient.user?.mobile || "-"}
                </TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {patient.user?.email || "-"}
                </TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {patient?.gender || "-"}
                </TableCell>

                <TableCell sx={tableStyles.tableCell}>
                  {getFormattedDate(patient?.createdAt || "-")}
                </TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {patient?.isFirstAppointmentCompleted ? (
                    <CheckCircleIcon
                      sx={{ color: "var(--neuro-button-bg-success)" }}
                    />
                  ) : (
                    <PendingIcon
                      sx={{ color: "var(--neuro-button-bg-primary)" }}
                    />
                  )}
                </TableCell>

                <TableCell sx={tableStyles.subTableCell}>
                  {patient?.reasonsForAppointments || "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PatientsList;
