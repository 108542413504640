import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FilterByMobileDropdownProps } from "../../types/common";

const FilterByMobileDropdown: React.FC<FilterByMobileDropdownProps> = ({
  options,
  selectedValue,
  setSelectedValue,
  open,
  handleOpen,
  handleClose,
}) => {
  // callbacks & functions
  const handleChange = (event: SelectChangeEvent<typeof selectedValue>) => {
    setSelectedValue(event.target.value);
  };

  return (
    <FormControl sx={{ minWidth: "8.75rem", minHeight: "1.25rem" }}>
      <Select
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={selectedValue}
        onChange={handleChange}
        IconComponent={KeyboardArrowDownIcon}
        sx={{
          backgroundColor: "var(--neuro-white-text)", // White background
          borderRadius: "0.625rem", // Rounded corners
          boxShadow: "0 0 0.5rem rgba(0, 0, 0, 0.1)", // Remove default shadow
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent", // Remove the default border
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--neuro-secondary_border)", // Light border on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--neuro-secondary_border)", // Light border when focused
          },
          ".MuiSelect-select": {
            padding: "0.5rem 1rem", // Padding for the selected value
            display: "flex",
            alignItems: "center",
          },
          ".MuiSvgIcon-root": {
            color: "var(--neuro-black-text)",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              backgroundColor:
                selectedValue === option.value
                  ? "var(--neuro-button-bg-success) !important"
                  : "inherit",
              color:
                selectedValue === option.value
                  ? "var(--neuro-white-text) !important"
                  : "inherit",
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterByMobileDropdown;
