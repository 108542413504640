import React, { useState } from "react";

// third-party imports
import { Box, Typography, useMediaQuery } from "@mui/material";

// project imports
import { NewAppointmentListProps, Option } from "../../../types/appointments";
import ComponentHeader from "../../shared/ComponentHeader";
import TabAppointments from "./TabAppointments";
import FilterByAppointments from "./FilterByAppointments";
import FilterByMobileDropdown from "../../shared/FilterByMobileDropdown";
import useAppStore from "../../../store/appStore";
// import DashboardFilter from "../../shared/DashboardFilter";

const NewAppointmentList: React.FC<NewAppointmentListProps> = ({
  appointments,
  loadingFetchAppointments,
  errorFetchAppointments,
  upcomingAppointments,
  ongoingAppointments,
  completedAppointments,
  cancelledAppointments,
  patientId,
  from = "",
}) => {
  // props & state values
  const isMobile = useMediaQuery("(max-width:37.5rem)");

  console.log("appointments", appointments);

  const options: Option[] = [
    { value: "All", label: "All" },
    { value: "Ongoing", label: "Ongoing" },
    { value: "Upcoming", label: "Upcoming" },
    { value: "Completed", label: "Completed" },
    { value: "Cancelled", label: "Cancelled" },
  ];

  const [open, setOpen] = useState(false);
  const { selectedAppointmentsFilter, setSelectedAppointmentsFilter } =
    useAppStore();

  // callbacks & functions
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getFilteredAppointments = () => {
    if (selectedAppointmentsFilter === "All") {
      return appointments;
    } else if (selectedAppointmentsFilter === "Ongoing") {
      return ongoingAppointments;
    } else if (selectedAppointmentsFilter === "Upcoming") {
      return upcomingAppointments;
    } else if (selectedAppointmentsFilter === "Completed") {
      return completedAppointments;
    } else if (selectedAppointmentsFilter === "Cancelled") {
      return cancelledAppointments;
    } else {
      return appointments;
    }
  };

  return (
    <Box
      sx={{
        paddingBlock: from === "dashboard" ? 0 : "1rem",
        paddingInline: from === "dashboard" ? 0 : "0.875rem",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!patientId && (
        <Box
          sx={{
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ComponentHeader
            title={from === "dashboard" ? "My Appointments" : "Appointments"}
          />
          {/* {from !== "dashboard" && <DashboardFilter />} */}
        </Box>
      )}

      {/* filter */}
      {from !== "dashboard" &&
        (isMobile ? (
          <Box
            sx={{
              marginBottom: "1rem",
              width: "max-content",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "var(--neuro-font-size-extra-small)",
                fontWeight: "var(--neuro-font-weight-semibold)",
              }}
            >
              Filter by:
            </Typography>

            <FilterByMobileDropdown
              open={open}
              selectedValue={selectedAppointmentsFilter}
              setSelectedValue={setSelectedAppointmentsFilter}
              handleClose={handleClose}
              handleOpen={handleOpen}
              options={options}
            />
          </Box>
        ) : (
          <Box
            sx={{
              marginBottom: "1rem",
              padding: "0.313rem 0.938rem",
              backgroundColor: "white",
              borderRadius: "1.875rem",
              width: "max-content",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              fontSize: "var(--neuro-font-size-extra-small)",
              height: "2.25rem",
              "& >.MuiFormGroup-root": {
                height: "2.25rem !important",
                maxHeight: "2.25rem !important",
              },
            }}
            className="outlined-secondary-button"
          >
            <Typography
              sx={{
                fontSize: "var(--neuro-font-size-extra-small)",
                fontWeight: "var(--neuro-font-weight-semibold)",
              }}
            >
              Filter by:
            </Typography>

            <FilterByAppointments
              selectedValue={selectedAppointmentsFilter}
              setSelectedValue={setSelectedAppointmentsFilter}
              options={options}
              from={from}
            />
          </Box>
        ))}

      {/* tabs content */}
      <TabAppointments
        appointments={getFilteredAppointments()}
        loadingFetchAppointments={loadingFetchAppointments}
        errorFetchAppointments={errorFetchAppointments}
        from={from}
        patientId={patientId}
      />
    </Box>
  );
};

export default NewAppointmentList;
