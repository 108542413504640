import React, { useEffect } from "react";
import { Box } from "@mui/material";
import ComponentHeader from "../shared/ComponentHeader";
import Button from "../ui/Button";
import { useNavigate } from "react-router-dom";
import AppointmentConfirmation from "../appointments/AppointmentConfirmation";
// import Speciality from "../appointments/Speciality";
import NewFlow from "../appointments/AppointmentBooking/NewFlow";
// import { DocumentsProps } from "../../types/documents";
import { PatientAppointmentsProps } from "../../types/common";
import AllAppointments from "../appointments/Appointments/AllAppointments";
import { isAdmin } from "../../utils/auth";
import useAuthStore from "../../store/authStore";
import useAppStore from "../../store/appStore";

const PatientAppointments: React.FC<PatientAppointmentsProps> = ({
  patientId,
  patientName,
  patientEmail,
  patientContact,
  from = "",
}) => {
  const navigate = useNavigate();
  const { userRole } = useAuthStore();

  const { appointmentConferenceView, setAppointmentConferenceView } =
    useAppStore();

  const path = window.location.pathname;

  const handleBookAppointment = () => {
    if (from === "meet") {
      setAppointmentConferenceView("Book");
    } else {
      if (isAdmin(userRole)) {
        navigate(`/patients/${patientId}/appointments/book`);
      } else {
        navigate(`/appointments/patient/${patientId}/appointments/book`);
      }
    }
  };

  useEffect(() => {
    if (!patientId) {
      if (isAdmin(userRole)) {
        navigate("/patients");
      } else {
        navigate("/appointments");
      }
    }
  }, [patientId]);

  return (
    <>
      {from === "meet" ? (
        <>
          {appointmentConferenceView === "List" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1.25rem",
                width: "100%",
                height: "100%",
              }}
            >
              {/* <ComponentHeader title="">
                <Button
                  color="primary"
                  variant="contained"
                  className="primary-button"
                  onClick={handleBookAppointment}
                >
                  Book Appointment
                </Button>
              </ComponentHeader> */}
              <AllAppointments patientId={patientId} from={from} />
            </Box>
          )}

          {appointmentConferenceView === "Book" && (
            <NewFlow
              patientId={patientId}
              patientName={patientName}
              patientEmail={patientEmail}
              patientContact={patientContact}
              from="meet"
            />
          )}

          {appointmentConferenceView === "Details" && (
            <AppointmentConfirmation from="meet" patientId={patientId} />
          )}
        </>
      ) : (
        <>
          {path.includes("details") ? (
            <AppointmentConfirmation patientId={patientId} />
          ) : //  : path.includes("specialties") && !path.match(/specialties\/[^/]+$/) ? (
          //   <Speciality patientId={patientId} />
          // )
          //  : path.match(/specialties\/[^/]+$/) ? (
          //   <NewFlow
          //     patientId={patientId}
          //     patientName={patientName}
          //     patientEmail={patientEmail}
          //     patientContact={patientContact}
          //   />
          // )
          path.includes("book") ? (
            <NewFlow
              patientId={patientId}
              patientName={patientName}
              patientEmail={patientEmail}
              patientContact={patientContact}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1.25rem",
                width: "100%",
                height: "100%",
              }}
            >
              <ComponentHeader title="">
                <Button
                  color="primary"
                  variant="contained"
                  className="primary-button"
                  onClick={handleBookAppointment}
                >
                  Book Appointment
                </Button>
              </ComponentHeader>
              <AllAppointments patientId={patientId} from={from} />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default PatientAppointments;
