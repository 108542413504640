import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";

interface BreadcrumbItem {
  label: string;
  href?: string;
}

interface CustomBreadcrumbsProps {
  items: BreadcrumbItem[];
}

const CustomBreadcrumbs: React.FC<CustomBreadcrumbsProps> = ({ items }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {items.map((item, index) =>
        item.href ? (
          <Link
            underline="none"
            className="page-heading"
            href={item.href}
            key={index}
          >
            {item.label}
          </Link>
        ) : (
          <Typography
            className="page-heading"
            key={index}
            sx={{ color: "var(--neuro-darkgrey_border) !important" }}
          >
            {item.label}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
