import { Box, Typography } from "@mui/material";
import useAuthStore from "../../store/authStore";
import { ADMIN, DOCTOR, NETWORK, PATIENT } from "../../utils/constants";
import PatientDashboard from "./PatientDashboard";
import DoctorDashboard from "./DoctorDashboard";
import AdminDashboard from "./AdminDashboard";
import NetworkDashboard from "./NetworkDashboard";
import DashboardFilter from "../shared/DashboardFilter";
import useAppStore from "../../store/appStore";

const PatientMonitor = () => {
  // props & state value
  const { user, userRole } = useAuthStore();
  const { range } = useAppStore();

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        // height: "100%",
        paddingBlock: "1rem",
        paddingInline: "0.875rem",
      }}
    >
      {userRole === PATIENT && (
        <Typography sx={{ mb: "1rem" }} className="page-heading">
          Welcome, {user?.name}!
        </Typography>
      )}

      {userRole !== PATIENT && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          marginBottom={2}
        >
          <Typography className="page-heading">
            Welcome, {user?.name}!
          </Typography>

          <DashboardFilter />
        </Box>
      )}

      {userRole === PATIENT && <PatientDashboard />}
      {userRole === DOCTOR && <DoctorDashboard range={range} />}
      {userRole === ADMIN && <AdminDashboard range={range} />}
      {userRole === NETWORK && <NetworkDashboard range={range} />}
    </Box>
  );
};

export default PatientMonitor;
