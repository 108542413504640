import React, { useEffect, useState } from "react";

// third-party imports
import { Box, Grid } from "@mui/material";

// project imports
import { loadDashboard } from "../../services/dashboardService";
import DoctorsTeamIcon from "../svg/DoctorsTeamIcon";
import PatientsIcon from "../svg/PatientsIcon";
import HospitalsIcon from "../svg/HospitalsIcon";
import RefferralsIcon from "../svg/RefferralsIcon";
import AppointmentsIcon from "../svg/AppointmentsIcon";
import DashboardCard from "./DashboardCard";
import { getStartAndEndDateTimesForRange } from "../../utils/common";
import useAppStore from "../../store/appStore";

interface AdminDashboardProps {
  range: string;
}

interface Statistics {
  [key: string]: number;
  patientsCount: number;
  clinicsCount: number;
  referralsCount: number;
  bookingAppointmentsCount: number;
  onGoingAppointmentsCount: number;
  clinicsCountTotal: number;
  doctorsCountTotal: number;
  patientsCountTotal: number;
}

const AdminDashboard: React.FC<AdminDashboardProps> = ({ range }) => {
  // props & state values
  const [statistics, setStatistics] = useState<Statistics>({
    doctorsCount: 0,
    patientsCount: 0,
    clinicsCount: 0,
    referralsCount: 0,
    bookingAppointmentsCount: 0,
    onGoingAppointmentsCount: 0,
    clinicsCountTotal: 0,
    doctorsCountTotal: 0,
    patientsCountTotal: 0,
  });

  const { customStartDate, customEndDate } = useAppStore();

  const cardsData = [
    {
      name: "doctorsCount",
      icon: <DoctorsTeamIcon />,
      text: "Doctors",
    },
    {
      name: "patientsCount",
      icon: <PatientsIcon />,
      text: "Patients",
    },
    {
      name: "clinicsCount",
      icon: <HospitalsIcon />,
      text: "Clinics",
    },
    {
      name: "newReferralsCount",
      icon: <RefferralsIcon />,
      text: "New Referrals",
    },
    {
      name: "acceptedReferralsCount",
      icon: <RefferralsIcon />,
      text: "Accepted Referrals",
    },
    {
      name: "rejectedReferralsCount",
      icon: <RefferralsIcon />,
      text: "Rejected Referrals",
    },
    {
      name: "bookingAppointmentsCount",
      icon: <AppointmentsIcon />,
      text: "Booked Appointments",
    },
    {
      name: "completedAppointmentsCount",
      icon: <AppointmentsIcon />,
      text: "Completed Appointments",
    },
    {
      name: "cancelledAppointmentsCount",
      icon: <AppointmentsIcon />,
      text: "Cancelled Appointments",
    },
  ];

  // callbacks & functions
  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        if (customStartDate && customEndDate) {
          console.log(
            "customStartDate",
            "customEndDate in doctor dashoard",
            customStartDate,
            customEndDate
          );

          const response = await loadDashboard(customStartDate, customEndDate);
          const data = response.data;
          setStatistics(data);
        } else {
          if (range === "TODAY") {
            const { start, end } = getStartAndEndDateTimesForRange(range);
            const response = await loadDashboard(start, end);
            const data = response.data;
            setStatistics(data);
            console.log("startDate", "end in doctor dashoard", start, end);
          }
        }
        //   const response = await loadDashboard(customStartDate, customEndDate);
        // const data = response.data;
        // setStatistics(data);
      } catch (error) {
        console.error("error in dashboard api", error);
      }
    };

    fetchDashboard();
  }, [range, customStartDate, customEndDate]);

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <Grid container spacing={2.5}>
          {cardsData?.map((data, i) => (
            <Grid item xs={12} md={4} key={i}>
              <DashboardCard
                icon={data?.icon}
                count={statistics ? statistics[data?.name] || 0 : 0}
                total={statistics ? statistics[data?.name + "Total"] || 0 : 0}
                text={data?.text}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default AdminDashboard;
