import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import Button from "../../ui/Button";
import { PdfProps } from "../../../types/documents";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs?v=1.0.0",
  import.meta.url
).toString();

const PdfViewer: React.FC<PdfProps> = ({ pdfUrl }: PdfProps) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfSrc, setPdfScr] = useState(pdfUrl || null);

  useEffect(() => {
    setPdfScr(pdfUrl);
  }, [pdfUrl]);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const prevPage = () => {
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  };

  const nextPage = () => {
    setPageNumber(pageNumber + 1 > numPages ? pageNumber : pageNumber + 1);
  };

  return (
    <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "0.625rem",
        }}
      >
        {numPages > 1 && (
          <Box sx={{ display: "flex", gap: "0.625rem", alignItems: "center" }}>
            <Button
              variant="outlined"
              disabled={pageNumber === 1}
              onClick={prevPage}
            >
              Prev
            </Button>
            <Button
              variant="outlined"
              disabled={pageNumber === numPages}
              onClick={nextPage}
            >
              Next
            </Button>
          </Box>
        )}
        <Typography>
          showing page {pageNumber} of {numPages}
        </Typography>
      </Box>

      <Document file={pdfSrc} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          renderTextLayer={false}
          renderAnnotationLayer={false}
          pageNumber={pageNumber}
        />
      </Document>
    </div>
  );
};

export default PdfViewer;
