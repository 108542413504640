const config = {
  APP_NAME: process.env.APP_NAME || "22Neuro Clinic",
  VERSION: process.env.VERSION || "Test",
  RAZORPAY_KEY_ID: process.env.RAZORPAY_KEY_ID || "rzp_test_bbZsNKZTldBRBU",
  RAZORPAY_DESCRIPTION:
    process.env.RAZORPAY_DESCRIPTION || "Appointment Booking",
  GOOGLE_MAPS_API_KEY:
    process.env.GOOGLE_MAPS_API_KEY ||
    "AIzaSyC1RyEK7PkeuJkR-Oskd54kQfCMpoTFsms",
  MEETING_ENABLE_TIME: 60,
};

export default config;
