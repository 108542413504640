import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PatientAbhaDetailsProps } from "../../types/documents";
import AbhaCardDetails from "../profile/patient/AbhaCardDetails";
import { isAdmin } from "../../utils/auth";
import useAuthStore from "../../store/authStore";

const PatientAbhaDetails: React.FC<PatientAbhaDetailsProps> = ({ userId }) => {
  const navigate = useNavigate();
  const { userRole } = useAuthStore();

  useEffect(() => {
    if (!userId) {
      if (isAdmin(userRole)) {
        navigate("/patients");
      } else {
        navigate("/appointments");
      }
    }
  }, [userId]);

  return <AbhaCardDetails userId={userId || ""} />;
};

export default PatientAbhaDetails;
