import React, { useEffect, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PatientSignUpModalProps } from "../../types/patients";
import PatientSignUp from "./PatientSignUp";
import PatientEmail from "./PatientEmail";
import PatientOtp from "./PatientOtp";
import Modal from "../ui/Modal";

const PatientSignUpModal: React.FC<PatientSignUpModalProps> = ({
  open,
  onClose,
  loadPatients,
  ...props
}) => {
  // props & state values
  const theme = useTheme();

  const [email, setEmail] = useState<any>("");
  const [otpToken, setOtpToken] = useState<any>("");
  const [isSignUpRequired, setIsSignUpRequired] = useState<boolean>(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // callbacks & functions
  const handleClose = () => {
    onClose();
    setEmail("");
    setOtpToken("");
    setIsSignUpRequired(false);
  };

  useEffect(() => {
    // cleanup on unmount
    return () => {
      setEmail("");
      setOtpToken("");
      setIsSignUpRequired(false);
    };
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeIcon
      width={isMobile ? "20rem" : "33.75rem"}
      {...props}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {otpToken !== "" && !isSignUpRequired
          ? ""
          : !isSignUpRequired && !!!otpToken
            ? "Email Verification"
            : isSignUpRequired
              ? "Patient Details"
              : ""}
      </DialogTitle>
      <DialogContent sx={{ overflow: "auto" }}>
        {otpToken !== "" && !isSignUpRequired && (
          <PatientOtp
            email={email}
            setIsSignUpRequired={setIsSignUpRequired}
            otpToken={otpToken}
          />
        )}

        {isSignUpRequired && (
          <PatientSignUp
            email={email}
            loadPatients={loadPatients}
            handleClose={handleClose}
          />
        )}

        {!isSignUpRequired && !!!otpToken && (
          <PatientEmail setEmail={setEmail} setOtpToken={setOtpToken} />
        )}
      </DialogContent>
    </Modal>
  );
};

export default PatientSignUpModal;
