export const isDoctorOrAdmin = (role: string | null): boolean => {
  return role === "DOCTOR" || role === "ADMIN";
};

export const isDoctor = (role: string | null): boolean => {
  return role === "DOCTOR";
};

export const isAdmin = (role: string | null): boolean => {
  return role === "ADMIN";
};

export const isPatient = (role: string | null): boolean => {
  return role === "PATIENT";
};

export const isNetwork = (role: string | null): boolean => {
  return role === "NETWORK";
};
