import { create } from "zustand";
import {
  getAppointments,
  cancelAppointmentBooking,
} from "../services/appointmentsService";
import { AppointmentCardProps, Medicine } from "../types/appointments";
import {
  getAppointmentStatus,
  sortAppoointmentsByDateTime,
} from "../utils/appointments";
import { CANCELLED, COMPLETED, ONGOING, UPCOMING } from "../utils/constants";

export interface AppointmentsStore {
  // States for fetching appointments
  appointments: any[];
  sortedAppointments: any[];
  dashboardSortedAppointments: any[];
  upcomingAppointments: any[];
  ongoingAppointments: any[];
  completedAppointments: any[];
  cancelledAppointments: any[];
  loadingFetchAppointments: boolean;
  errorFetchAppointments: string | null;
  fetchAppointments: (id?: string, query?: string) => Promise<void>;
  cleanUpAppointments: () => void;

  // States for canceling appointments
  cancelAppointmentInfo: any;
  loadingCancelAppointment: boolean;
  errorCancelAppointment: string | null;
  cancelAppointment: (appointmentId: string) => Promise<void>;
  cleanUpCancelAppointmentState: () => void;

  // States for conference
  medicines: Medicine[];
  notes: string[];
  complaints: string[];
  advices: string[];
  setMedicines: (data: any) => void;
  setNotes: (data: any) => void;
  setComplaints: (data: any) => void;
  setAdvices: (data: any) => void;

  // States for appointments
  selectedDoctor: any;
  selectedAppointmentId: any;
  setSelectedAppointmentId: any;
  selectedSpeciality: any;
  selectedDoctorSlotId: string | null;
  selectedDoctorDate: Date | null;
  selectedMeetingType: string;
  yourAvailabilitySelected: boolean;
  setYourAvailabilitySelected: any;
  appointmentDrawerOpen: boolean;
  availableSlotId: string | null;
  selectedDate: Date | null;
  selectedTime: string | null;
  setSelectedDate: any;
  setSelectedTime: any;
  setAvailableSlotId: any;
  appointmentDate: any;
  appointmentId: string | null;
  setSelectedSpeciality: any;
  setAppointmentId: any;
  setAppointmentDate: any;
  setAppointmentDrawer: any;
  setSelectedDoctor: any;
  setSelectedDoctorSlotId: any;
  setSelecteDoctordDate: any;
  setSelectedMeetingType: any;
  cleanUpDoctor: any;
  cleanUpDoctorDate: any;
  cleanUpDoctorSlotId: any;
  cleanUpAppointmentDate: any;
  cleanUpMeetingType: any;
  cleanUpTime: any;
  cleanUpSlotId: any;
  cleanUpAppointmentDrawerOpen: any;
  cleanUpAppointmentId: any;
  cleanUpAppointmentState: any;
}

const useAppointmentStore = create<AppointmentsStore>((set) => ({
  // Initial states for fetching appointments
  appointments: [],
  sortedAppointments: [],
  dashboardSortedAppointments: [],
  upcomingAppointments: [],
  ongoingAppointments: [],
  completedAppointments: [],
  cancelledAppointments: [],
  loadingFetchAppointments: false,
  errorFetchAppointments: null,
  fetchAppointments: async (id?: string, query?: string) => {
    set({ loadingFetchAppointments: true, errorFetchAppointments: null });
    try {
      const response = await getAppointments(id, query);

      if (response.data && response.data.length > 0) {
        const sortedByOrderAppointments = sortAppoointmentsByDateTime(
          response.data
        );

        const appointmentsArry: AppointmentCardProps[] = [];
        response.data?.forEach((appointment: any) => {
          if (
            appointment?.availableSlot?.startTime &&
            appointment?.availableSlot?.endTime
          ) {
            const result = getAppointmentStatus(
              appointment.availableSlot.startTime,
              appointment.availableSlot.endTime
            );

            if (
              result !== COMPLETED &&
              appointment?.status !== COMPLETED &&
              appointment.status !== CANCELLED
            ) {
              appointmentsArry.push(appointment);
            }
          } else {
            console.warn(
              "Appointment does not have valid availableSlot",
              appointment
            );
          }
        });

        if (appointmentsArry.length > 0) {
          const sortedByOrderAppointments =
            sortAppoointmentsByDateTime(appointmentsArry);
          set({ dashboardSortedAppointments: sortedByOrderAppointments });
        }

        const upcoming =
          response.data?.filter(
            (appointment: any) =>
              getAppointmentStatus(
                appointment?.availableSlot?.startTime,
                appointment?.availableSlot?.endTime
              ) === UPCOMING && appointment.status !== CANCELLED
          ) || [];

        const ongoing =
          response.data?.filter(
            (appointment: any) =>
              getAppointmentStatus(
                appointment?.availableSlot?.startTime,
                appointment?.availableSlot?.endTime
              ) === ONGOING && appointment.status !== CANCELLED
          ) || [];

        const completed =
          response.data?.filter(
            (appointment: any) =>
              (getAppointmentStatus(
                appointment?.availableSlot?.startTime,
                appointment?.availableSlot?.endTime
              ) === COMPLETED ||
                appointment.status === COMPLETED) &&
              appointment.status !== CANCELLED
          ) || [];

        const cancelled =
          response.data?.filter(
            (appointment: any) => appointment.status === CANCELLED
          ) || [];

        set({
          appointments: response.data,
          sortedAppointments: sortedByOrderAppointments || [],
          upcomingAppointments: upcoming,
          ongoingAppointments: ongoing,
          completedAppointments: completed,
          cancelledAppointments: cancelled,
          loadingFetchAppointments: false,
        });
      } else {
        set({
          appointments: response.data,
          sortedAppointments: [],
          dashboardSortedAppointments: [],
          upcomingAppointments: [],
          ongoingAppointments: [],
          completedAppointments: [],
          cancelledAppointments: [],
          loadingFetchAppointments: false,
        });
      }
    } catch (error) {
      set({
        errorFetchAppointments: "Something went wrong!",
        loadingFetchAppointments: false,
      });
    }
  },
  cleanUpAppointments: () => {
    set({
      appointments: [],
      loadingFetchAppointments: false,
      errorFetchAppointments: null,
    });
  },

  // Initial states for canceling appointments
  cancelAppointmentInfo: null,
  loadingCancelAppointment: false,
  errorCancelAppointment: null,
  cancelAppointment: async (appointmentId: string) => {
    set({ loadingCancelAppointment: true, errorCancelAppointment: null });
    const data = { status: "CANCELLED" };
    try {
      const response = await cancelAppointmentBooking(appointmentId, data);
      set({
        cancelAppointmentInfo: response.data.message,
        loadingCancelAppointment: false,
      });
    } catch (error) {
      set({
        errorCancelAppointment: "Something went wrong!",
        loadingCancelAppointment: false,
      });
    }
  },
  cleanUpCancelAppointmentState: () => {
    set({
      cancelAppointmentInfo: null,
      loadingCancelAppointment: false,
      errorCancelAppointment: null,
    });
  },

  // Initial states for conference
  medicines: [
    {
      medication: "",
      medicationType: "",
      dosageAmount: "",
      dosageUnit: "",
      frequency: "",
      durationAmount: "",
      durationUnit: "",
    },
  ],
  notes: [],
  setMedicines: (data: any) => set({ medicines: data }),
  setNotes: (data: any) => set({ notes: data }),
  complaints: [],
  setComplaints: (data: any) => set({ complaints: data }),
  advices: [],
  setAdvices: (data: any) => set({ complaints: data }),

  // Initial states for appointments
  selectedDoctor: null,
  selectedAppointmentId: null,
  selectedSpeciality: null,
  selectedMeetingType: "ONLINE",
  selectedDoctorSlotId: "",
  availableSlotId: null,
  selectedDoctorDate: null,
  appointmentDrawerOpen: false,
  appointmentId: null,
  appointmentDate: null,
  yourAvailabilitySelected: false,
  selectedDate: new Date(),
  selectedTime: null,
  setSelectedDoctor: (doctor: any) => set({ selectedDoctor: doctor }),
  setSelectedAppointmentId: (id: any) => set({ selectedAppointmentId: id }),
  setAvailableSlotId: (slotId: string) => set({ availableSlotId: slotId }),
  setSelectedDoctorSlotId: (slotId: string) =>
    set({ selectedDoctorSlotId: slotId }),
  setAppointmentDate: (date: any) => set({ appointmentDate: date }),
  setSelecteDoctordDate: (date: any) => set({ selectedDoctorDate: date }),
  setSelectedDate: (date: any) => set({ selectedDate: date }),
  setSelectedTime: (time: string | null) => set({ selectedTime: time }),
  setSelectedMeetingType: (meeting: any) =>
    set({ selectedMeetingType: meeting }),
  setAppointmentDrawer: (open: boolean) => set({ appointmentDrawerOpen: open }),
  setAppointmentId: (id: string) => set({ appointmentId: id }),
  setYourAvailabilitySelected: (value: boolean) =>
    set({ yourAvailabilitySelected: value }),
  setSelectedSpeciality: (value: string | null) =>
    set({ selectedSpeciality: value }),
  cleanUpDoctor: () => set({ selectedDoctor: null }),
  cleanUpSpeciality: () => set({ selectedSpeciality: null }),
  cleanUpTime: () => set({ selectedTime: null }),
  cleanUpAppointmentDate: () => set({ appointmentDate: null }),
  cleanUpMeetingType: () => set({ selectedMeetingType: "ONLINE" }),
  cleanUpSlotId: () => set({ availableSlotId: null }),
  cleanUpAppointmentDrawerOpen: () => set({ appointmentDrawerOpen: false }),
  cleanUpAppointmentId: () => set({ appointmentId: null }),
  cleanUpDoctorDate: () => set({ selectedDoctorDate: null }),
  cleanUpDoctorSlotId: () => set({ selectedDoctorSlotId: null }),
  cleanUpAppointmentState: () =>
    set({
      selectedDoctor: null,
      selectedMeetingType: "ONLINE",
      selectedDoctorSlotId: "",
      availableSlotId: null,
      selectedDoctorDate: null,
      appointmentDrawerOpen: false,
      appointmentId: null,
      appointmentDate: null,
      yourAvailabilitySelected: false,
      selectedDate: new Date(),
      selectedTime: null,
      selectedSpeciality: null,
    }),
}));

export default useAppointmentStore;
