const PatientsIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    fill="none"
    {...props}
  >
    <g fill="#000" fillRule="evenodd" clipRule="evenodd">
      <path d="M41.25 28.25H36c-.75 0-1.25-.5-1.25-1.25s.5-1.25 1.25-1.25h5.25c1.25 0 2.5-.25 3.75-.5.75-.25 1.25-.5 1.75-.75s.75-1 .75-1.5-.25-1-.5-1.5C45.75 20 43.5 19 40.5 18c-.25 0-.25-.25-.5-.25-.75-.5-1.25-1.25-1.5-2 0-1 .5-1.75 1.25-2.25 1.75-2.5 2.75-4.75 2.5-6.75-.25-1.25-.75-2.25-1.75-3-1.75-1.25-4.25-1.25-5.75 0-1 .75-1.5 1.75-1.75 3-.25 2 .75 4.25 2.5 6.75.5.5.25 1.25-.25 1.75S34 15.5 33.5 15c-2.5-3-3.5-6-3-8.75.25-1.75 1.25-3.5 2.75-4.5 2.5-2 6.25-2 8.75 0 1.5 1.25 2.5 2.75 2.75 4.5C45 9 44 12 41.75 15c-.25.25-.25.5-.5.5 0 0 .25 0 .25.25 3.25 1 5.75 2.5 7.25 4.25.75.75 1.25 2 1.25 3.25a4.374 4.374 0 0 1-1.75 3.5c-.75.5-1.5 1-2.5 1.25-1.5 0-3 .25-4.5.25Zm-27.25 0H8.75c-1.75 0-3.25-.25-4.5-.75-1-.25-1.75-.75-2.5-1.25C.75 25.5 0 24 0 22.75s.5-2.25 1.25-3.25c1.75-1.75 4.25-3 7.5-4 0 0 .25 0 .25-.25 0 0-.25-.25-.25-.5C6.25 11.75 5 8.5 5.5 6c.25-1.75 1.25-3.5 2.75-4.5 2.5-2 6.25-2 8.75 0 1.5 1.25 2.5 2.75 2.75 4.5.25 2.75-.75 5.75-3 8.75-.5.501-1.25.501-1.75.251-.5-.5-.75-1.25-.25-1.75 2-2.5 2.75-5 2.5-6.75 0-1.25-.75-2.25-1.75-3.001-1.75-1.25-4-1.25-5.75 0C8.75 4.25 8 5.25 8 6.25c-.25 2 .75 4.25 2.5 6.75.5.75 1.25 1.5 1 2.25 0 1.001-.75 1.5-1.5 2 0 0-.25.25-.5.25-2.75 1.25-4.75 2.25-6.25 3.751-.25.5-.5 1-.5 1.5 0 .75.25 1.25.75 1.5s1 .5 1.75.75c1 .5 2.25.75 3.5.75H14c.75 0 1.25.5 1.25 1.25s-.5 1.25-1.25 1.25h-.002Z" />
      <path d="M30.25 50H20c-2.25 0-4.25-.25-6-1-1.25-.5-2.25-1-3-1.75-1.5-.75-2.25-2.5-2.25-4.25 0-1.5.5-2.75 1.5-4 2-2.25 5.25-4 9.75-5.5l.75-.75L20 32c-3.25-4.25-4.749-8-4.25-11.5.501-2.251 1.5-4.25 3.5-5.752 3.25-2.249 8.25-2.249 11.25 0 2 1.502 3.25 3.5 3.5 5.751C34.5 24 33 28 30 32c-.75.75-.75 1-.75 1s.501.25.75.5c4.5 1.5 7.75 3.25 9.75 5.5 1 1 1.501 2.5 1.501 4 0 1.75-.75 3.5-2 4.5-.75.75-1.75 1.25-3 1.75-2 .5-4 .75-6 .75ZM25 15.249c-1.5 0-3 .5-4.25 1.25-1.5 1-2.25 2.5-2.5 4.25-.25 2.75 1 6 3.5 9.5.75.75 1.5 1.5 1.25 2.75 0 1.001-1 1.75-1.75 2.5 0 0-.25.25-.5.25-4.249 1.251-7 3-9 5-.25.501-.5 1.501-.5 2.251 0 1 .5 2 1.25 2.5.5.5 1.5 1 2.5 1.25 1.5.5 3.25.75 5 .75h10.25c1.75 0 3.5-.25 5-.75 1.001-.25 1.75-.75 2.5-1.25S39 44 39 43c0-.75-.25-1.75-1-2.25-1.75-2-4.75-3.5-8.999-5-.25 0-.25-.25-.5-.25-.75-.75-1.75-1.5-1.75-2.5s.75-2 1.5-2.75c2.5-3.25 3.75-6.751 3.5-9.5-.25-1.75-1-3-2.5-4.25-1.25-.75-2.75-1.251-4.25-1.251Z" />
    </g>
  </svg>
);
export default PatientsIcon;
